import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import { auth, toast, payment } from '../../redux';
import NotificationPanel from '../NotificationPanel';
import {
  formatString,
  useFetchApi,
  Config,
  useRedirect,
  Translator,
  ModalCategoryReceipt,
} from '@itarget/shared';

import useStyles from './style';
import {
  Grid,
  Hidden,
  Icon,
  Typography,
  Divider,
  useMediaQuery,
  Popper,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import BtnLanguagens from '../BtnLanguagens';

const StyledMenu = withStyles({
  paper: {
    width: '255px',
    marginLeft: '10px',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const PageLayout = (props) => {
  const { goTo } = useRedirect();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { centro_custo_id } = useParams();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorNotification, setAnchorNotification] = useState(null);
  const [openNotification, setOpenNotification] = useState(true);
  const [openModalCategory, setOpenModalCategory] = useState(false);

  const user = useSelector((state) => state.auth.user);
  const language = useSelector((state) => state.auth.language);
  const selectedLang = useSelector((state) => state.language.lang);

  const {
    data: followUp,
    // isLoading: isLoadingFollowUp,
    fetchData: fetchFollowUp,
  } = useFetchApi(
    Config.baseURL +
    `api/${selectedLang}/follow-up?centro_custo_id=${centro_custo_id}&pessoa_id=${user.data.id}&status=1`,
  );

  useEffect(() => {
    fetchFollowUp();
  }, []);

  useEffect(() => {
    if (
      followUp &&
      followUp.filter((res) => res.data_view === null).length === 0
    ) {
      setOpenNotification(false);
    }
  }, [followUp]);

  useEffect(() => {
    setAnchorNotification(document.getElementById('menu-notification'));
  }, [document.getElementById('menu-notification')]);

  const logout = () => {
    dispatch(auth.actions.logout());
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setOpenNotification(!openNotification);
  };

  let labelExit = Translator({ transRef: 'translation.Sair' }).props.children;

  return (
    <div style={{ left: 0 }}>
      <Popper
        className={classes.popper}
        open={openNotification}
        anchorEl={anchorNotification}
        placement={'bottom-end'}
      >
        <NotificationPanel setOpenNotification={setOpenNotification} />
      </Popper>
      {openModalCategory && (
        <ModalCategoryReceipt
          centerCostId={centro_custo_id}
          toast={toast}
          payment={payment}
          open={openModalCategory}
          setOpen={() => setOpenModalCategory()}
        />
      )}
      <Grid className={classes.header}>
        <div className={classes.content}>
          <img
            className={classes.logoHeader}
            src={
              useMediaQuery('(max-width:737px)')
                ? `/assets/images/clients/${Config.client}/${centro_custo_id}/${language ? language : 'pt-br'
                }/logos/logoLite.png`
                : `/assets/images/clients/${Config.client}/${centro_custo_id}/${language ? language : 'pt-br'
                }/logos/logo.png`
            }
            alt=" "
            onError={(event) => (event.target.style.display = 'none')}
          />
        </div>
        <div className={classes.content}>
          <Hidden mdDown className={classes.itensMenu}>
            <MenuItem onClick={() => setOpenModalCategory(true)}>
              <Icon color="action">insert_drive_file</Icon>
              <Typography>
                <Translator transRef={'translation.documentos'} />
              </Typography>
            </MenuItem>
            <Divider orientation="vertical" flexItem />
            <MenuItem onClick={() => goTo(`course/${centro_custo_id}`)}>
              <Icon color="action">home</Icon>
              <Typography>
                <Translator transRef={'translation.Início'} />
              </Typography>
            </MenuItem>
            <MenuItem onClick={() => goTo(`listing/${centro_custo_id}`)}>
              <Icon color="action">subject</Icon>
              <Typography>
                <Translator transRef={'translation.Inscrições'} />
              </Typography>
            </MenuItem>
            <MenuItem onClick={() => goTo(`faq/${centro_custo_id}`)}>
              <Icon color="action">speaker_notes</Icon>
              <Typography>
                <Translator transRef={'translation.Dúvidas'} />
              </Typography>
            </MenuItem>
          </Hidden>
          <Divider orientation="vertical" flexItem />
          <MenuItem
            id="menu-notification"
            aria-describedby={'notification-popper'}
            onClick={handleClick}
            style={{ padding: 10 }}
          >
            <Icon style={{ color: '#940F00', fontSize: 30 }}>
              notifications
            </Icon>
          </MenuItem>
          <Divider orientation="vertical" flexItem />
          <MenuItem
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
            }}
          >
            <Grid className={classes.gridUser}>
              <Grid className={classes.userName} item xs={12}>
                {user.data && (
                  <Typography>
                    <Translator
                      transRef={`translation.
                      ${user.data.nome_cracha
                          ? formatString.toLowerCase(user.data.nome_cracha)
                          : user.data.nome
                            ? formatString.toLowerCase(user.data.nome)
                            : ''
                        }
                    `}
                    />
                  </Typography>
                )}
              </Grid>
              <Grid className={classes.userCategoria} item xs={12}>
                <Typography>
                  <Translator
                    transRef={`translation.
                  ${user.data &&
                      user.data.categoria_centro_custo_descricao &&
                      formatString.toLowerCase(
                        user.data.categoria_centro_custo_descricao,
                      )
                      }
                `}
                  />
                </Typography>
              </Grid>
            </Grid>
            <img
              className={classes.imgUser}
              src={
                user.data && user.data.foto
                  ? user.data.foto
                  : `/assets/images/logos/avatar.svg`
              }
              alt="Logo"
            />
            <Hidden lgUp>
              <Grid item xs={12}>
                <Icon color="action">menu</Icon>
              </Grid>
            </Hidden>
          </MenuItem>
          <StyledMenu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <Hidden lgUp>
              <MenuItem onClick={() => goTo(`course/${centro_custo_id}`)}>
                <ListItemIcon>
                  <Icon color="action">home</Icon>
                </ListItemIcon>
                <Typography>
                  <Translator transRef={'translation.Início'} />
                </Typography>
              </MenuItem>
              <MenuItem onClick={() => goTo(`listing/${centro_custo_id}`)}>
                <ListItemIcon>
                  <Icon color="action">shopping_cart</Icon>
                </ListItemIcon>
                <Typography>
                  <Translator transRef={'translation.Inscrições'} />
                </Typography>
              </MenuItem>
              <MenuItem onClick={() => goTo(`faq/${centro_custo_id}`)}>
                <ListItemIcon>
                  <Icon color="action">speaker_notes</Icon>
                </ListItemIcon>
                <Typography>
                  <Translator transRef={'translation.Dúvidas'} />
                </Typography>
              </MenuItem>
            </Hidden>
            {/* <MenuItem>
              <ListItemIcon>
                <Icon color="action">person</Icon>
              </ListItemIcon>
              <ListItemText primary="Perfil" />
            </MenuItem> */}
            <MenuItem onClick={() => logout()}>
              <ListItemIcon>
                <Icon color="action">exit_to_app</Icon>
              </ListItemIcon>
              <ListItemText primary={labelExit} />
            </MenuItem>
          </StyledMenu>
        </div>
      </Grid>
      {props.content}
      <Hidden mdDown>
        <Grid className={classes.footer}>
          <Translator
            transRef={
              'translation.© 2021 - Itarget Tecnologia - Todos os direitos reservados'
            }
          />
          <BtnLanguagens page="other" centroCusto={centro_custo_id} />
        </Grid>
      </Hidden>
    </div>
  );
};

export default PageLayout;
