import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Config, useFetchApi, useRedirect } from '@itarget/shared';
import { toast } from '../../redux';
import { useFormik } from 'formik';

import ChangePasswordView from './views/ChangePasswordView';

const ChangePasswordContainer = (props) => {
  const { goTo } = useRedirect();
  const dispatch = useDispatch();
  const { centro_custo_id } = useParams();
  const language = useSelector((state) => state.course.language);
  const selectedLang = useSelector((state) => state.language.lang);

  const {
    data: changePassword,
    error,
    fetchData: fetchChangePassword,
  } = useFetchApi(Config.baseURL + `api/${selectedLang}/change-password`, 'POST');

  useEffect(() => {
    if (error !== null) {
      dispatch(
        toast.actions.enqueue({
          content: error,
          severity: 'error',
        }),
      );
    }
    if (changePassword !== null) {
      dispatch(
        toast.actions.enqueue({
          content: changePassword.message,
          severity: 'success',
        }),
      );
      goTo(`login/${centro_custo_id}`);
    }
  }, [changePassword, error]);

  const onSubmit = (values) => {
    values.centro_custo_id = centro_custo_id;
    if (values.senha === values.confirma_senha) {
      fetchChangePassword(JSON.stringify(values));
    }
  };

  const formik = useFormik({
    initialValues: { codigo: '', senha: '', confirma_senha: '' },
    enableReinitialize: true,
    onSubmit,
  });

  return (
    <ChangePasswordView
      props={props}
      centro_custo_id={centro_custo_id}
      language={language}
      formik={formik}
      goTo={goTo}
      selectedLang={selectedLang}
    />
  );
};

export default ChangePasswordContainer;
