import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import 'react-credit-cards/es/styles-compiled.css';
import {
  Grid,
  Typography,
  Container,
  Paper,
  IconButton,
  Icon,
  Box,
} from '@material-ui/core';
import useStyles from './style';
import {
  formatMoney,
  useFetchApi,
  Config,
  Loading,
  Translator,
} from '@itarget/shared';
import Layout from '../../../components/Layout';
import DiscountViewContainer from '../Course/DiscountViewContainer';

const FinishedViewContainer = () => {
  const classes = useStyles();
  const subscription = useSelector((state) => state.payment.subscription);
  const selectedLang = useSelector((state) => state.language.lang);
  const settingDollar = useSelector((state) => state.payment.settingDollar);
  const [incIds, setIncIds] = useState('');
  const [incList, setIncList] = useState();
  const [vlTotal, setVlTotal] = useState();

  const {
    data: accountReceive,
    isLoading: isLoadingAccountReceive,
    fetchData: fetchAccountReceive,
  } = useFetchApi(
    Config.baseURL +
      `api/${selectedLang}/finance/account-receive/` +
      incIds +
      '/receipt',
  );

  useEffect(() => {
    if (subscription.length > 0) {
      let ids = subscription.map((res, i) => {
        return res.conta_receber_id;
      });
      setIncIds(ids.toString());
    }
  }, []);

  useEffect(() => {
    if (incIds && incIds.length > 0) {
      fetchAccountReceive();
    }
  }, [incIds]);

  useEffect(() => {
    if (accountReceive) {
      setIncList(accountReceive.data);
    }
  }, [accountReceive]);

  useEffect(() => {
    if (incList && incList.length > 0) {
      let vl = 0;
      incList.map((e) => {
        vl = vl + parseFloat(e.r_a_importancia_de);
      });
      setVlTotal(vl);
    }
  }, [incList]);

  return (
    <Layout
      content={
        <Container className={classes.containerFinished} maxWidth="xl">
          <Box display="block" displayPrint="none">
            <DiscountViewContainer viewFinished viewPayment={false} />
          </Box>
          <Grid container className={classes.grid} spacing={4}>
            <Grid item xs={12} className={classes.gridButtons}>
              <Grid container>
                <Grid item xs={9}>
                  <Typography className={classes.textHeader} gutterBottom>
                    <Translator
                      transRef={'translation.Compra realizada com sucesso!'}
                    />
                  </Typography>
                </Grid>
                <Grid item xs={3} className={classes.actions}>
                  <IconButton
                    className={classes.btnSaveFinished}
                    color="primary"
                    aria-label="add to shopping cart"
                    onClick={() => window.print()}
                  >
                    <Icon
                      className={classes.btnSaveIcon}
                      style={{ fontSize: 50 }}
                    >
                      print
                    </Icon>
                  </IconButton>
                  {/* <IconButton
                    className={classes.btnSaveFinished}
                    color="primary"
                    aria-label="add to shopping cart"
                  >
                    <Icon
                      className={classes.btnSaveIcon}
                      style={{ fontSize: 50 }}
                    >
                      save
                    </Icon>
                  </IconButton> */}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} className={classes.gridRecibo}>
              <Paper className={classes.headerRecibo}>
                <Grid container>
                  <Grid item xs={9}>
                    <object
                      type="image/svg+xml"
                      data={`/assets/images/clients/${Config.client}/logos/logo.svg`}
                      width="120"
                      height="80"
                    />
                    {isLoadingAccountReceive ? (
                      <></>
                    ) : incList ? (
                      <Grid container xs>
                        <Grid item className={classes.infoHeader}>
                          <Typography gutterBottom>
                            {`${incList[0].e_endereco}, ${incList[0].e_numero}, ${incList[0].e_complemento}, ${incList[0].e_cep}, `}
                            {`${incList[0].e_municipio} - ${incList[0].e_uf}`}
                          </Typography>
                          <Typography gutterBottom>
                            {incList[0].e_fone}
                          </Typography>
                          <Typography gutterBottom>
                            {incList[0].e_cnpj}
                          </Typography>
                        </Grid>
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    <Typography className={classes.textFatura} gutterBottom>
                      <Translator transRef={'translation.FATURA'} />
                    </Typography>
                    {isLoadingAccountReceive ? (
                      <></>
                    ) : incList ? (
                      <Grid container xs>
                        <Grid item className={classes.infoHeader}>
                          <Typography gutterBottom>
                            {incList[0].num_nosso_numero}
                          </Typography>
                          <Typography gutterBottom>
                            {incList[0].f_cr_data_vencimento}
                          </Typography>
                          <Typography gutterBottom>
                            {incList[0].recibo_numero}
                          </Typography>
                        </Grid>
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </Grid>
              </Paper>
              <Paper className={classes.dadosRecibo}>
                {isLoadingAccountReceive ? (
                  <></>
                ) : incList ? (
                  <Grid item className={classes.infoDados}>
                    <Typography className={classes.infoNome} gutterBottom>
                      {incList[0].r_recebemos_de}
                    </Typography>
                    <Typography gutterBottom>
                      {incList[0].pessoa_cpf}
                    </Typography>
                  </Grid>
                ) : (
                  <></>
                )}
              </Paper>
              <Paper className={classes.descRecibo}>
                <Grid container>
                  <Grid container className={classes.descReciboHeader}>
                    <Grid item xs={9}>
                      <Typography gutterBottom>
                        <Translator transRef={'translation.Descrição'} />
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography className={classes.vlDesc} gutterBottom>
                        <Translator transRef={'translation.Valor'} />
                      </Typography>
                    </Grid>
                  </Grid>
                  {isLoadingAccountReceive ? (
                    <Loading text="Carregando..." />
                  ) : (
                    incList &&
                    incList.map((row, i) => (
                      <Grid
                        container
                        className={classes.descReciboBody}
                        key={i}
                      >
                        <Grid item xs={9}>
                          {row.agenda_atividade_idioma_descricao}
                        </Grid>
                        <Grid item xs={3} className={classes.vlDesc}>
                          {formatMoney.toReal(row.r_a_importancia_de)}

                          {settingDollar?.filter((sd) => sd.codigo === 683)
                            ?.length > 0 &&
                            '/$' +
                              formatMoney.toReal(
                                row.r_a_importancia_de /
                                  settingDollar?.filter(
                                    (sd) => sd.codigo === 683,
                                  )[0].valor_referencia,
                              )}
                        </Grid>
                      </Grid>
                    ))
                  )}
                  {isLoadingAccountReceive ? (
                    <></>
                  ) : incList ? (
                    <Grid container>
                      <Grid item xs={9}>
                        <></>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography className={classes.txtVlTotal} gutterBottom>
                          <Translator transRef={'translation.TOTAL'} />
                        </Typography>
                        <Typography className={classes.vlTotal} gutterBottom>
                          R$ {formatMoney.toReal(vlTotal)}
                          {settingDollar?.filter((sd) => sd.codigo === 683)
                            ?.length > 0 &&
                            '/$' +
                              formatMoney.toReal(
                                vlTotal /
                                  settingDollar?.filter(
                                    (sd) => sd.codigo === 683,
                                  )[0].valor_referencia,
                              )}
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : (
                    <></>
                  )}
                </Grid>
                <Typography className={classes.txtFooter} gutterBottom>
                  <Translator
                    transRef={'translation.Agradecemos por sua aquisição'}
                  />
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      }
    />
  );
};

export default FinishedViewContainer;
