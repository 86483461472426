import {
  // take,
  all,
  //  put, delay, fork, cancel, call
} from 'redux-saga/effects';
// import * as actions from './actions';

// function doSelectCourse() {
// }

function* watchForCourse() {
  // while (true) {
  //   yield take(actions.SET_SELECTED_COURSE);
  //   yield call(doSelectCourse);
  // }
}

function* rootSaga() {
  yield all([watchForCourse()]);
}

export default rootSaga;
