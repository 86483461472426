import React from 'react';
import Toast from '../Toast';
import useStyles from './style';

const Toaster = ({ toasts = [], onClose }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {toasts.slice(0, 3).map((toast) => (
        <Toast
          key={toast.id}
          id={toast.id}
          content={toast.content}
          severity={toast.severity}
          onClose={onClose}
        />
      ))}
    </div>
  );
};

export default Toaster;
