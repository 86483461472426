import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    zIndex: 1000,
    width: '100%',
    height: '100vh',
    background: 'rgb(255 255 255 / 79%)',
    backdropFilter: 'blur(3px)',
  },
  gridContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('lg')]: {
      margin: '35%',
    },
  },
  text: {
    fontWeight: 'bold',
    color: '#3FD3FF',
    textAlign: 'center',
  },
  textInfo: {
    fontWeight: 'lighter',
    margin: '10px 60px',
    textAlign: 'center',
    fontSize: 27,
  },
  grid: {
    display: 'flex',
    margin: 20,
    alignItems: 'center',
    '& .MuiTypography-root': {
      fontSize: 12,
    },
    '& .MuiFormControlLabel-root': {
      marginRight: 0,
    },
  },
  btnSubmit: {
    height: 70,
    borderRadius: 40,
    background: 'white',
    color: '#3FD3FF',
    fontSize: 30,
    fontWeight: 100,
    border: '2px solid #3FD3FF',
  },
}));

export default useStyles;
