import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  CardContent,
  Icon,
  Grid,
  Paper,
  Button,
  TextField,
} from '@material-ui/core';
import '../../style.scss';

import { Config, Translator } from '@itarget/shared';

const RecoveryView = ({ props, centro_custo_id, language, formik, goTo }) => {
  return (
    <>
      <Grid className={'header'}>
        <div className={'content'}>
          <object
            type="image/svg+xml"
            data={
              props.pathImage
                ? props.pathImage
                : `/assets/images/clients/${Config.client}/${centro_custo_id}/${
                    language ? language : 'pt-br'
                  }/logos/logo.svg`
            }
            width="120"
            height="80"
          />
        </div>
        <div className={'content'}>
          <img
            src={
              props.pathImage
                ? props.pathImage
                : `/assets/images/clients/${Config.client}/${centro_custo_id}/${
                    language ? language : 'pt-br'
                  }/logos/logo-administrador.png`
            }
            height="50"
          />
        </div>
      </Grid>
      <Grid container spacing={0} className={'rootRecovery'}>
        {!centro_custo_id && (
          <div>
            <Dialog aria-labelledby="simple-dialog-title" open={true}>
              <DialogTitle id="simple-dialog-title">
                <Translator transRef={'translation.Evento nao encontrado!'} />
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <Translator transRef={'translation.Verifique se a url fornecida esta completa.'} />
                </DialogContentText>
              </DialogContent>
            </Dialog>
          </div>
        )}
        <Grid item xs={12}>
          <Grid item>
            <Typography
              variant="h2"
              component="h2"
              color="textSecondary"
              align="center"
              gutterBottom
              className={'textHead'}
            >
              {' '}
              <Translator transRef={'translation.Esqueci minha senha'} />
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Paper className={'paper'}>
            <CardContent>
              <Grid>
                <Icon
                  style={{
                    color: 'darkgreen',
                    marginRight: 5,
                    marginBottom: -5,
                  }}
                >
                  how_to_reg
                </Icon>
                <Typography
                  variant="caption"
                  gutterBottom
                  style={{ color: 'darkgreen' }}
                >
                  <Translator transRef={'translation.TENHO LOGIN'} />
                </Typography>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <form onSubmit={formik.handleSubmit}>
                    <Grid item xs={12} className={'gridBtn'}>
                      <TextField
                        type={'text'}
                        variant="standard"
                        label={'email'}
                        name="login"
                        className={'field'}
                        onChange={formik.handleChange}
                        required={true}
                      />
                    </Grid>
                    <Grid item xs={12} className={'gridBtn'}>
                      <Button
                        type="submit"
                        variant="contained"
                        className={'btnSubmit'}
                      >
                        <Translator transRef={'translation.RECUPERAR SENHA'} />
                      </Button>
                    </Grid>
                  </form>
                  <Button
                    className={'btnBack'}
                    onClick={() =>
                      goTo(`login/${centro_custo_id}`)
                    }
                  >
                    <Translator transRef={'translation.Voltar'} />
                  </Button>
                </div>
              </Grid>
            </CardContent>
          </Paper>
        </Grid>
      </Grid>
      <Grid className={'footer'}>
        <Translator transRef={'translation.© 2021 - Itarget Tecnologia - Todos os direitos reservados'} />
      </Grid>
    </>
  );
};

export default RecoveryView;
