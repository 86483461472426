import * as actions from './actions';
import produce from 'immer';

const initialState = {
  settings: [],
};

export const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case actions.SET_SETTINGS:
      state.settings = action.payload.settings;
      break;
  }
}, initialState);
