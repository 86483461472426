import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { useSelector} from 'react-redux';//, useDispatch 
import { Config, Translator } from '@itarget/shared';
import {
  Typography,
  Hidden,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Icon,
  Divider,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  vertical: (props) => ({
    width: 350,
    height: '100vh',
    backgroundColor: '#050505',
    display: 'flex',
    alignItems: 'flex-start',
    position: 'fixed',
    justifyContent: 'center',
    left: 0,
    paddingTop: 195,
    [theme.breakpoints.down('md')]: {
      paddingTop: 220,
    },
    background: `url("/assets/images/clients/${Config.client}/${
      props.routeParams.centro_custo_id
    }/${props.language ? props.language : 'pt-br'}/bgCursos.jpeg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  }),
  horizontal: {
    width: '100%',
    bottom: '0',
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'fixed',
    zIndex: 9999,
    '& .MuiPaper-root': {
      width: '100%',
      margin: 0,
    },
  },
  accordion: {
    margin: '0 40px !important',
    borderRadius: '10px 10px 0 0 !important',
  },
  heading: {
    color: '#fff',
    fontWeight: 'bold',
    margin: 0,
    fontSize: 22,
    marginTop: -6,
  },
  accordionHeader: {
    backgroundColor: '#131b77',
    margin: 0,
    minHeight: '35px !important',
    borderRadius: '10px 10px 0 0',
    '& .MuiSvgIcon-root': {
      color: '#fff',
    },
    '& .MuiAccordionSummary-content': {
      margin: '12px 0 !important',
    },
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    maxHeight: '40vh',
    overflowX: 'hidden',
  },
  detailCourse: {
    flexDirection: 'column',
    display: 'flex',
    // justifyContent: 'space-between',
    alignItems: 'start',
    padding: '3px 10px',
  },
  textItem: {
    display: 'flex',
    alignItems: 'center',
    margin: 5,
  },
  iconItem: {
    fontSize: '1rem',
    margin: 5,
  },
  dividerItem: {
    width: '100%',
  },
}));

const ContactsViewContainer = (props) => {
  const routeParams = useParams();
  const language = useSelector((state) => state.course.language);
  const classes = useStyles({ routeParams, language });
  const settings = useSelector((state) => state.setting.settings);

  const [dataContact, setDataContact] = useState();

  useEffect(() => {
    setDataContact(
      settings?.filter((r) => r.codigo === 9)?.[0].valor_referencia.split('|'),
    );
  }, [settings]);

  const AccordionComponent = () => {
    return (
      <Accordion defaultExpanded={true} className={classes.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className={classes.accordionHeader}
        >
          <Typography className={classes.heading}>
            <Translator transRef={'translation.Contato'} />
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <div className={classes.detailCourse}>
            <Typography variant="caption" className={classes.textItem}>
              <Icon className={classes.iconItem}>phone</Icon> {dataContact?.[0]}
            </Typography>
            <Divider className={classes.dividerItem} />
            <Typography className={classes.textItem} variant="caption">
              <Icon className={classes.iconItem}>email</Icon> {dataContact?.[1]}
            </Typography>
            <Divider className={classes.dividerItem} />
            <Typography
              className={classes.textItem}
              style={{ cursor: 'pointer' }}
              variant="caption"
              onClick={() => window.open(dataContact?.[2], '_blank')}
            >
              <Icon className={classes.iconItem}>link</Icon>
              {dataContact?.[2]}
            </Typography>
          </div>
        </AccordionDetails>
      </Accordion>
    );
  };

  return (
    <>
      <Hidden smDown>
        <div className={classes.vertical}>
          <AccordionComponent />
        </div>
      </Hidden>
      <Hidden mdUp>
        <div className={classes.horizontal}>
          <AccordionComponent />
        </div>
      </Hidden>
    </>
  );
};

export default ContactsViewContainer;
