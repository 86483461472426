import * as actions from './actions';
import produce from 'immer';

const initialState = {
  isAutenticated: false,
  isAutenticating: false,
  token: null,
  user: {},
  categoriaCC: '',
  authorizedAccess: true,
};

export const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case actions.SET_USER:
      state.user = action.payload.user;
      state.token = action.payload.token;
      break;
    case actions.SET_IS_AUTENTICATED:
      state.isAutenticated = action.payload.isAutenticated;
      break;
    case actions.AUTHORIZED_ACCESS:
      state.authorizedAccess = action.payload.authorizedAccess;
      break;
    case actions.UPDATE_USER:
      state.user = action.payload.user;
      break;
    case actions.LOGIN.STARTED:
      state.isAutenticating = true;
      state.isAutenticated = false;
      break;
    case actions.LOGIN.FULFILLED:
      state.isAutenticated = true;
      state.isAutenticating = false;
      state.user = action.payload;
      break;
    case actions.LOGIN.FAILED:
      state.isAutenticated = false;
      state.isAutenticating = false;
      break;
    case actions.LOGOUT:
      state.isAutenticated = false;
      state.isAutenticating = false;
      state.token = null;
      state.user = {};
      state.categoriaCC = '';
      state.authorizedAccess= true;
      break;
  }
}, initialState);
