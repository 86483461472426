import React, { useEffect, useState } from 'react';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';

import {
  TextField,
  Grid,
  MenuItem,
  Typography,
  FormControlLabel,
  Container,
  Collapse,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import { Translator } from '@itarget/shared';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Layout from '../../../components/Layout';
import ContactsViewContainer from './ContactsViewContainer';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexGrow: 1,
    '& .MuiTypography-h5': {
      fontSize: 17,
    },
  },
  grid: {
    padding: '120px 3%',
    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
      marginLeft: 350,
    },
    [theme.breakpoints.down('sm')]: {
      padding: '100px 0px',
      margin: 0,
      marginBottom: 150,
    },
  },
  textHeader: {
    color: '#919191',
    fontWeight: 100,
    marginBottom: 40,
    [theme.breakpoints.up('sm')]: {
      fontSize: 30,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
      textAlign: 'center',
    },
  },
  container: {
    background: '#ededed',
    [theme.breakpoints.down('sm')]: {
      '& .MuiFormControlLabel-root': {
        width: '100%',
        marginLeft: 0,
        marginRight: 0,
        marginBottom: '10%',
      },
    },
    [theme.breakpoints.down('md')]: {
      '& .MuiFormControlLabel-root': {
        width: '100%',
        marginLeft: 0,
        marginRight: 0,
        marginBottom: '10%',
      },
    },
    [theme.breakpoints.down('lg')]: {
      '& .MuiFormControlLabel-root': {
        width: '100%',
        height: '100%',
        marginLeft: 0,
        marginRight: 0,
        marginBottom: '10%',
      },
    },
  },
  heading: {},
  description: {
    color: '#6b6b6b',
  },
}));

const FaqViewContainer = () => {
  const classes = useStyles();
  const [listQuestions, setListQuestions] = useState([]);

  const settings = useSelector((state) => state.setting.settings);
  const lang = useSelector((state) => state.language.lang);

  const configIdLanguage = lang === 'es' ? 3 : lang === 'en' ? 2 : 1; 

  useEffect(() => {
    if (settings?.find((r) => r.codigo === 13)?.valor_referencia) {
      setListQuestions(
        JSON.parse(settings?.find((r) => r.codigo === 13 && r.idioma_id === configIdLanguage)?.valor_referencia),
      );
    }
  }, [lang]);

  return (
    <Layout
      content={
        <Container className={classes.container} maxWidth="xl">
          <div className={classes.root}>
            <ContactsViewContainer />
            <Grid container spacing={4} className={classes.grid}>
              <Grid container spacing={2} xs={12}>
                <Grid item xs>
                  <Typography className={classes.textHeader} gutterBottom>
                    <Translator transRef={'translation.Dúvidas? Confira as perguntas frequentes e fale conosco'} />
                  </Typography>
                  <Grid>
                    {listQuestions?.map((row, i) => (
                      <Accordion defaultExpanded={true}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className={classes.heading}>
                            <Translator transRef={`translation.${i + 1}- ${row.pergunta}`} />
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography className={classes.description}>
                            <Translator transRef={`translation.${row.descricao}`} />
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={3}></Grid>
            </Grid>
          </div>
        </Container>
      }
    />
  );
};

export default FaqViewContainer;
