import React, { useState, useEffect } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { auth, toast, language as languageRedux } from '../../redux';
import ModalPdp from '../../components/ModalPdp';
import {
  Config,
  useFetchApi,
  debounce,
  configurator,
  checkJwt,
  validateEmail,
  useRedirect,
} from '@itarget/shared';
import LoginView from './views/LoginView';

const LoginContainer = (props) => {
  const dispatch = useDispatch();
  const { centro_custo_id, jwt } = useParams();
  const [tipoLogin, setTipoLogin] = useState();
  const [rulesStation, setRulesStation] = useState();
  const [typeLoginDesc, setTypeLoginDesc] = useState();
  const isAutenticated = useSelector((state) => state.auth.isAutenticated);
  const authorizedAccess = useSelector((state) => state.auth.authorizedAccess);
  const [openPdp, setOpenPdp] = useState(false);
  const [value, setValue] = useState(0);
  const [checkedAuthorize, setCheckedAuthorize] = useState(false);
  const [valueModalPdp, setValueModalPdp] = useState();
  const [textLoginStation, setTextLoginStation] = useState('');
  const [rulesLGPD, setRulesLGPD] = useState('');

  const language = useSelector((state) => state.course.language);
  const selectedLang = useSelector((state) => state.language.lang);

  const indicator = selectedLang === 'en' ? 2 : selectedLang === 'es' ? 3 : 1;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handlePdpOpen = (value) => {
    setValueModalPdp(value);
    setOpenPdp(true);
  };

  const handlePdpClose = () => {
    setOpenPdp(false);
  };
  const { data: setting, fetchData: fetchSetting } = useFetchApi(
    Config.baseURL +
      `api/${selectedLang}/setting?centro_custo_id=${centro_custo_id}&${configurator.LOGIN}`,
  );

  useEffect(() => {
    fetchSetting();
  }, [selectedLang]);

  const onValidJwt = (validateJwt) => {
    dispatch(auth.actions.authorizedAccess(false));
    dispatch(auth.actions.setUser(validateJwt));
  };

  const { isLoadingValidateJwt } = checkJwt({
    jwt: jwt,
    centro_custo_id: centro_custo_id,
    onValid: onValidJwt,
  });

  useEffect(() => {
    if (!isAutenticated) {
      dispatch(auth.actions.setUser([]));
    }
  }, [isAutenticated]);

  useEffect(() => {
    if (setting && setting.data) {
      setRulesLGPD(
        setting.data.filter((res) => res.codigo === 956 && res.idioma_id === indicator)?.[0]?.valor_referencia,
      );
      setTextLoginStation(
        setting.data.filter((res) => res.codigo === 309).length > 0 &&
          setting.data.filter((res) => res.codigo === 309)[0].valor_referencia,
      );
      setRulesStation(
        setting.data.filter((res) => res.codigo === 313 && res.idioma_id === indicator)?.[0]?.valor_referencia,
      );
      let tld =
        setting.data.filter((res) => res.codigo === 223).length > 0 &&
        setting.data.filter((res) => res.codigo === 223)[0].valor_referencia;

      if (tld == 'email_cpf') {
        tld = 'Email/CPF';
      }

      setTypeLoginDesc(tld);
      setTipoLogin(
        setting.data.filter((res) => res.codigo === 223).length > 0 &&
          setting.data.filter((res) => res.codigo === 223)[0].valor_referencia,
      );
    }
  }, [setting]);

  const validate = (values) => {
    const errors = {};
    if (values !== undefined) {
      switch (tipoLogin) {
        case 'matricula':
          break;
        case 'cnpj':
          if (!isNaN(values.login) && values.login.length > 14) {
            errors.login = 'Preencha o CNPJ corretamente';
          }

          break;
        case 'uf_conselho':
          break;
        default:
          if (!isNaN(values.login) && values.login.length > 11) {
            errors.login = 'Preencha o CPF corretamente';
          } else if (
            isNaN(values.login) &&
            !validateEmail.isValid(values.login)
          ) {
            errors.login = 'Preencha o email corretamente';
          }
          return errors;
      }
    }
  };

  const onSubmit = async (values) => {
    values['centro_custo_id'] = centro_custo_id;
    dispatch({
      type: 'FETCH_STARTED',
      meta: {
        actions: auth.actions.LOGIN,
        body: JSON.stringify(values),
        url: Config.baseURL + `api/${selectedLang}/login`,
        method: 'POST',
      },
    });
  };

  const formik = useFormik({
    initialValues: { login: '', senha: '' },
    enableReinitialize: true,
    onSubmit,
    validate,
  });

  const onChange = debounce((target) => {
    formik.setFieldValue(target.name, target.value);
  });

  const onClickAuthorize = () => {
    if (checkedAuthorize) {
      dispatch(auth.actions.setIsAutenticated(true));
      return false;
    }
    dispatch(
      toast.actions.enqueue({
        content: 'Aceite os termos para prosseguir',
        severity: 'error',
      }),
    );
  };

  const handleAuthorize = () => {
    setCheckedAuthorize(!checkedAuthorize);
  };

  if (isAutenticated) {
    return <Redirect to={'/course/' + centro_custo_id} />;
  }

  const setLanguage = (lang) => {
    dispatch(languageRedux.actions.setLang(lang));
  };

  return (
      <LoginView
        typeLoginDesc={typeLoginDesc}
        value={value}
        props={props}
        setting={setting}
        textLoginStation={textLoginStation}
        language={language}
        centro_custo_id={centro_custo_id}
        authorizedAccess={authorizedAccess}
        checkedAuthorize={checkedAuthorize}
        openPdp={openPdp}
        rulesStation={rulesStation}
        valueModalPdp={valueModalPdp}
        rulesLGPD={rulesLGPD}
        handlePdpOpen={handlePdpOpen}
        handleChange={handleChange}
        handleAuthorize={handleAuthorize}
        onClickAuthorize={onClickAuthorize}
        ModalPdp={ModalPdp}
        handlePdpClose={handlePdpClose}
        setLanguage={ setLanguage }
        lang={selectedLang}
      />
  );
};

export default LoginContainer;
