import {
  all,
  put,
  delay,
  call,
  takeEvery,
  select,
} from 'redux-saga/effects';
// import { Config } from '@itarget/shared';

import * as actions from './actions';
import {
  selectors as selectorsCourse,
  actions as actionsCourse,
} from '../course';
import { actions as toastActions } from '../toast';
// import { actions as paymentActions } from '../payment';
// import { actions as fetchActions } from '../fetch';

function* voucherFulfilled(action) {
  const selectedCourse = action.meta.selectedCourse;
  // const user = action.meta.user;
  // const currDiscount = action.payload.data.agenda_atividade_id
    // ? selectedCourse.filter(
        // (r) => r.aga_id === action.payload.data.agenda_atividade_id,
      // )
    // : selectedCourse[0];

  for (let i = 0; i < selectedCourse.length; i++) {
    // yield put(
    //   fetchActions.fetchStarted({
    //     meta: {
    //       course: selectedCourse[i],
    //       voucher: currDiscount === selectedCourse[i] ? action.payload : null,
    //       actions: paymentActions.SUBSCRIPTION,
    //       body: JSON.stringify({
    //         pessoa_id: user.data && user.data.id,
    //         plano_pagamento_id: selectedCourse[i].plp_id,
    //         associacao_id: user.data && user.data.associacao_id,
    //         voucher_id:
    //           currDiscount === selectedCourse[i] ? action.payload.id : null,
    //       }),
    //       url: Config.baseURL + 'api/pt-br/subscription',
    //       method: 'POST',
    //     },
    //   }),
    // );
  }
}

function* voucherFailed(action) {
  yield put(
    toastActions.enqueue({
      content: action.payload.message,
      severity: 'error',
    }),
  );
}

function* subscriptionStarted() {}

function* applyDescount(action) {
  const coursesSelected = yield select(selectorsCourse.courseSelected);
  const descount = action.meta.voucher.desconto;
  const descountType = action.meta.voucher.tipo_desconto;
  const courseFilter = coursesSelected.filter(
    (r) => r === action.meta.course,
  )[0];

  if (descountType === 'P') {
    const valor =
      courseFilter.valor - courseFilter.valor * (parseFloat(descount) / 100);
    yield put(
      actionsCourse.updateSelectCourse({
        course: courseFilter,
        element: 'valor',
        value: valor,
      }),
    );
  }
  if (descountType === 'V') {
    const valor = courseFilter.valor - parseFloat(descount);
    yield put(
      actionsCourse.updateSelectCourse({
        course: courseFilter,
        element: valor,
        value: valor,
      }),
    );
  }
}

function* subscriptionFulfilled(action) {
  yield put(actions.setSubscription(action.payload.data));
  yield put(
    toastActions.enqueue({
      content: action.payload.message,
      severity: 'success',
    }),
  );

  yield delay(1000);
  yield put(actions.setInpayment());

  if (action.meta.voucher) {
    yield call(applyDescount, action);
  }
}

function* subscriptionFailed(action) {
  yield put(
    toastActions.enqueue({
      content: action.payload.message,
      severity: 'error',
    }),
  );
}

function* watchForSubscriptionStarted() {
  yield takeEvery(actions.SUBSCRIPTION.STARTED, subscriptionStarted);
}
function* watchForSubscriptionFulfilled() {
  yield takeEvery(actions.SUBSCRIPTION.FULFILLED, subscriptionFulfilled);
}
function* watchForSubscriptionFailed() {
  yield takeEvery(actions.SUBSCRIPTION.FAILED, subscriptionFailed);
}

function* watchForVoucherFulfilled() {
  yield takeEvery(actions.VOUCHER.FULFILLED, voucherFulfilled);
}

function* watchForVoucherFailed() {
  yield takeEvery(actions.VOUCHER.FAILED, voucherFailed);
}

function* rootSaga() {
  yield all([
    watchForSubscriptionStarted(),
    watchForSubscriptionFulfilled(),
    watchForSubscriptionFailed(),
    watchForVoucherFulfilled(),
    watchForVoucherFailed(),
  ]);
}

export default rootSaga;
