import React from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import { Translator } from '@itarget/shared';
import useStyles from './style';

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const Toast = ({ id, content, duration = 6000, severity, onClose }) => {
  const classes = useStyles();

  const onCloseHandler = () => {
    if (onClose) {
      onClose(id);
    }
  };
  React.useEffect(() => {
    const timeout = setTimeout(() => onClose(id), duration);
    return () => clearTimeout(timeout);
  }, [duration, id, onClose]);

  return (
    <Alert
      className={classes.toast}
      onClose={onCloseHandler}
      severity={severity}
    >
      <Translator transRef={`translation.${content}`} />
    </Alert>
  );
};

export default Toast;
