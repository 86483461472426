import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  accordion: {
    margin: '0 40px !important',
    borderRadius: '10px 10px 0 0 !important',
  },
  heading: {
    color: '#fff',
    fontWeight: 'bold',
    margin: 0,
    fontSize: 22,
    marginTop: -6,
  },
  accordionHeader: {
    backgroundColor: '#131b77',
    margin: 0,
    minHeight: '35px !important',
    borderRadius: '10px 10px 0 0',
    '& .MuiSvgIcon-root': {
      color: '#fff',
    },
    '& .MuiAccordionSummary-content': {
      margin: '12px 0 !important',
    },
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    maxHeight: '40vh',
    overflowX: 'hidden',
  },
  iconPrice: {
    color: '#fff',
    margin: '0 10px 0 0',
  },
  iconDiscount: {
    marginLeft: 5,
  },
  detailCourse: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '3px 10px',
  },
  detailDiscount: {
    display: 'flex',
    alignItems: 'center',
  },
  textTicket: {
    margin: '10px 20px',
  },
  iconClose: {
    fontSize: 15,
    marginLeft: 5,
    color: '#FA8080',
    cursor: 'pointer',
  },
  gridValue: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default useStyles;
