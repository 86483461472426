import React from 'react';
import { language as languageRedux } from '../../redux';
import {
  useFetchApi,
  Config,
} from "@itarget/shared";
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './style';
import { useEffect } from 'react';
import { useState } from 'react';

const BtnLanguagens = ({ centroCusto, page }) => {
  const [listLang,setListLang] = useState([]);
  const selectedLang = useSelector((state) => state.language.lang);
  const classes = useStyles();
  const dispatch = useDispatch();

  const setLanguage = (number) => {
    let lang = number === 2 ? 'en' : number === 3 ? 'es' : 'pt-br';
    dispatch(languageRedux.actions.setLang(lang));
  };

  const { data: language, fetchData: fetchLanguage } = useFetchApi(
    Config.baseURL +
      `api/${selectedLang}/language?centro_custo_id=${centroCusto}`
  );

    useEffect(() => {
      fetchLanguage();
    },[]);

    useEffect(() => {
      if(language){
        setListLang(language.data);
      }
    },[language]);

  return (
    <div className={ page === 'login' ? classes.btnsContainerLogin : classes.btnsContainerGeneral }>
      {listLang.map((row) => (
        <img
        src={`/assets/images/${row.value}.png`}
        alt={row.label}
        className={ page === 'login' ? classes.flagsLogin : classes.flagsGeneral }
        onClick={() => setLanguage(row.value)}
        />
      ))}
    </div>
  );
}

export default BtnLanguagens;
