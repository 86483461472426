import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  CardContent,
  Grid,
  Paper,
  Button,
  TextField,
} from '@material-ui/core';

import { Config, Translator } from '@itarget/shared';

const ChangePasswordView = ({
  props,
  centro_custo_id,
  language,
  formik,
  goTo,
}) => {
  return (
    <>
      <Grid className="header">
        <div className="content">
          <object
            type="image/svg+xml"
            data={
              props.pathImage
                ? props.pathImage
                : `/assets/images/clients/${Config.client}/${centro_custo_id}/${
                    language ? language : 'pt-br'
                  }/logos/logo.svg`
            }
            width="120"
            height="80"
          />
        </div>
        <div className="content">
          <img
            src={
              props.pathImage
                ? props.pathImage
                : `/assets/images/clients/${Config.client}/${centro_custo_id}/${
                    language ? language : 'pt-br'
                  }/logos/logo-administrador.png`
            }
            height="50"
          />
        </div>
      </Grid>
      <Grid container spacing={0} className="rootChange">
        {!centro_custo_id && (
          <div>
            <Dialog aria-labelledby="simple-dialog-title" open={true}>
              <DialogTitle id="simple-dialog-title">
                <Translator transRef={'translation.Evento nao encontrado!'} />
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <Translator transRef={'translation.Verifique se a url fornecida esta completa.'} />
                </DialogContentText>
              </DialogContent>
            </Dialog>
          </div>
        )}
        <Grid item xs={12}>
          <Grid item>
            <Typography
              variant="h2"
              component="h2"
              color="textSecondary"
              align="center"
              gutterBottom
            >
              <Translator transRef={'translation.Esqueci minha senha'} />
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs>
          <Paper className="paper">
            <CardContent>
              <Grid>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <form onSubmit={formik.handleSubmit}>
                    <TextField
                      type={'text'}
                      variant="standard"
                      label={'Código'}
                      name="codigo"
                      className="field"
                      onChange={formik.handleChange}
                      required={true}
                    />
                    <TextField
                      type="password"
                      variant="standard"
                      label="Senha"
                      name="senha"
                      className="field"
                      onChange={formik.handleChange}
                      required={true}
                      error={formik.errors['senha']}
                      helperText={formik.errors['senha']}
                    />
                    <TextField
                      type="password"
                      variant="standard"
                      label="Confirme a senha"
                      name="confirma_senha"
                      className="field"
                      onChange={formik.handleChange}
                      required={true}
                      error={formik.errors['senha']}
                      helperText={formik.errors['senha']}
                    />
                    <Grid item xs={12} className="gridBtn">
                      <Button
                        type="submit"
                        variant="contained"
                        className="btnSubmit"
                      >
                        <Translator transRef={'translation.Mudar senha'} />
                      </Button>
                    </Grid>
                  </form>
                  <Button
                    className="btnBack"
                    onClick={() => goTo(`login/recovery/${centro_custo_id}`)}
                  >
                    <Translator transRef={'translation.Voltar'} />
                  </Button>
                </div>
              </Grid>
            </CardContent>
          </Paper>
        </Grid>
      </Grid>
      <Grid className="footer">
        <Translator transRef={'translation.© 2021 - Itarget Tecnologia - Todos os direitos reservados'} />
      </Grid>
    </>
  );
};

export default ChangePasswordView;
