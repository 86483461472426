import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Icon,
  Grid,
  List,
  ListItem,
  ListItemText,
  Divider,
  CircularProgress,
} from '@material-ui/core';

import { Config } from '@itarget/shared';
import { useFetchApi, Translator } from '@itarget/shared';

const useStyles = makeStyles({
  gridContainer: {
    background: 'white',
    maxWidth: '400px',
  },
  listContainer: {
    width: '100%',
    backgroundColor: 'white',
    minWidth: '25vw',
  },
  gridHead: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  iconClose: {
    fontSize: '15px',
    cursor: 'pointer',
  },
  listTitle: {
    color: '#940F00',
    fontWeight: 'bolder',
    fontSize: 14,
  },
  boxEnd: {
    background: '#940F00',
    width: '100%',
    height: 10,
  },
  listItem: {
    cursor: 'pointer',
  },
  iconStart: {
    alignSelf: 'center',
    marginRight: 10,
  },
  listTitleSelected: {
    fontWeight: 'bolder',
  },
  iconBack: {
    fontSize: '3rem',
    color: '#3FD3FF',
    cursor: 'pointer',
  },
  iconCircle: {
    color: '#3fd3ff',
  },
  iconDone: {
    color: '#3CA53F',
  },
  gridGroupIcon: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  gridNoNotification: {
    background: '#fff',
  },
  textNoNotification: {
    padding: '10px',
  },
  gridLoading: {
    display: 'flex',
    justifyContent: 'center',
    padding: 10,
  },
});

const NotificationPanel = ({ setOpenNotification }) => {
  const classes = useStyles();
  const routeParams = useParams();
  const user = useSelector((state) => state.auth.user);

  const [listNotification, setListNotification] = useState([]);
  const [selectedNotification, setSelectedNotification] = useState();
  const selectedLang = useSelector((state) => state.language.lang);

  const { data: parceiros, fetchData: fetchParceiros } = useFetchApi(
    Config.baseURL +
      `api/${selectedLang}/entity/${routeParams.centro_custo_id}/partner?associacao_id=${user.data.associacao_id}&pessoa_id=${user.data.id}&centro_custo_id=${routeParams.centro_custo_id}`,
  );
  const {
    data: followUpCheck,
    isLoading: isLoadingFollowUpCheck,
    fetchData: fetchFollowUpCheck,
  } = useFetchApi(
    Config.baseURL +
      `api/${selectedLang}/follow-up/${user.data.id}/check?centro_custo_id=${routeParams.centro_custo_id}&tipo_aviso_id=6`,
  );

  const {
    data: followUp,
    isLoading: isLoadingFollowUp,
    fetchData: fetchFollowUp,
  } = useFetchApi(
    Config.baseURL +
      `api/${selectedLang}/follow-up?centro_custo_id=${routeParams.centro_custo_id}&pessoa_id=${user.data.id}&status=1`,
  );

  const { fetchData: fetchFollowUpUpdate } = useFetchApi(null, 'PUT');

  useEffect(() => {
    fetchFollowUpCheck();
    fetchParceiros();
  }, []);

  useEffect(() => {
    if (parceiros && parceiros.data && parceiros.data.status && followUpCheck) {
      fetchFollowUpCheck();
    }
  }, [parceiros]);

  useEffect(() => {
    if (followUpCheck) {
      fetchFollowUp();
    }
  }, [followUpCheck]);

  useEffect(() => {
    if (followUp) {
      setListNotification(followUp);
    }
  }, [followUp]);

  const selectNotification = async (row) => {
    await fetchFollowUpUpdate(
      JSON.stringify({
        status: 1,
        id: row.id,
        data_view: new Date(),
      }),
      Config.baseURL + `api/${selectedLang}/follow-up/${row.id}`,
    );
    fetchFollowUp();

    setSelectedNotification(row);
  };

  return (
    <Grid container className={classes.gridContainer}>
      {selectedNotification ? (
        <>
          <Grid item xs={1}>
            <Icon
              onClick={() => setSelectedNotification()}
              className={classes.iconBack}
            >
              keyboard_arrow_left
            </Icon>
          </Grid>
          <Grid item xs={11}>
            <List
              className={classes.listContainer}
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              <ListItem alignItems="flex-start">
                <Icon className={classes.iconStart} fontSize="small">
                  payments
                </Icon>
                <Typography className={classes.listTitleSelected}>
                  <Translator transRef={`translation.${selectedNotification.assunto}`} />
                </Typography>
              </ListItem>
              <Divider component="li" />
              <ListItem alignItems="flex-start">
                <Typography>
                  <Translator transRef={`translation.${selectedNotification.corpo}`} />
                </Typography>
              </ListItem>
            </List>
          </Grid>
        </>
      ) : (
        <List
          className={classes.listContainer}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <ListItem alignItems="flex-start">
            <Typography className={classes.listTitle}>
              <Translator transRef={'translation.Notificações'} />
            </Typography>
          </ListItem>
          {isLoadingFollowUpCheck || isLoadingFollowUp ? (
            <Grid className={classes.gridLoading}>
              <CircularProgress />
            </Grid>
          ) : (
            <>
              {listNotification.map((row) => (
                <>
                  <ListItem alignItems="flex-start">
                    <Icon
                      style={row.data_view && { color: '#828282' }}
                      className={classes.iconStart}
                      fontSize="small"
                    >
                      payments
                    </Icon>
                    <ListItemText
                      className={classes.listItem}
                      primary={row.assunto}
                      onClick={() => selectNotification(row)}
                      style={row.data_view && { color: '#828282' }}
                    />
                    {row.data_view ? (
                      <Icon className={classes.iconDone}>done_all</Icon>
                    ) : (
                      <Icon className={classes.iconCircle}>
                        add_circle_outline
                      </Icon>
                    )}
                  </ListItem>
                  <Divider component="li" />
                </>
              ))}
            </>
          )}
        </List>
      )}
      <div className={classes.boxEnd}></div>
    </Grid>
  );
};
export default NotificationPanel;
