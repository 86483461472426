import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { auth, language, payment } from '../redux';
import { Config, useFetchApi } from '@itarget/shared';

import RouteContainer from './RouteContainer';
import ToasterContainer from './ToasterContainer';
import CourseContainer from './views/Course/CourseContainer';
import FaqViewContainer from './views/Faq/FaqViewContainer';
import PaymentViewContainer from './views/Payment/PaymentViewContainer';
import FinishedViewContainer from './views/Payment/FinishedViewContainer';
import ListingViewContainer from './views/Payment/ListingViewContainer';

import LoginContainer from './login/LoginContainer';
import RecoveryContainer from './recovery/RecoveryContainer';
import ChangePasswordContainer from './changePassword/ChangePasswordContainer';
import { parseInt } from 'lodash';

const AppContainer = () => {
  const lang = useSelector((state) => state.language.lang);

  const urlAtual = window.location.href;
  const centerCost = urlAtual.split('/')[4];

  const dispatch = useDispatch();

  const { data: translation, fetchData: fetchTranslation } = useFetchApi(
    Config.baseURL + `api/${lang}/translation?centro_custo_id=${centerCost}`,
  );

  const { data: settingDollar, fetchData: fetchSettingDollar } = useFetchApi(
    Config.baseURL +
      `api/${lang}/setting?codigo[]=683&codigo[]=876&centro_custo_id=${centerCost}`,
  );

  useEffect(() => {
    getToken();
  }, []);

  useEffect(() => {
    if (Number.isInteger(parseInt(centerCost))) {
      fetchTranslation();
      fetchSettingDollar();
    }
  }, [lang]);

  useEffect(() => {
    if (settingDollar?.data) {
      dispatch(payment.actions.setSettingDollar(settingDollar.data));
    }
  }, [settingDollar]);

  useEffect(() => {
    if (translation?.data) {
      dispatch(language.actions.setTranslation(translation.data));
    }
  }, [translation]);

  const getToken = () => {
    const data = {
      login: 'icongressolive',
      senha: 'FRB:y}|Y',
    };
    const body = JSON.stringify(data);
    dispatch({
      type: 'FETCH_STARTED',
      meta: {
        actions: auth.actions.AUTHENTICATE_REQUEST,
        body: body,
        url: Config.baseURL + `api/${lang}/authenticate`,
        method: 'POST',
      },
    });
  };

  return (
    <Router>
      <ToasterContainer />
      <Switch>
        <RouteContainer isProtected exact path="/">
          <LoginContainer />
        </RouteContainer>
        <RouteContainer path="/login/:centro_custo_id/:jwt?">
          <LoginContainer />
        </RouteContainer>
        <RouteContainer path="/recovery/:centro_custo_id">
          <RecoveryContainer />
        </RouteContainer>
        <RouteContainer path="/change-password/:centro_custo_id">
          <ChangePasswordContainer />
        </RouteContainer>
        <RouteContainer isProtected path="/course">
          <CourseContainer />
        </RouteContainer>
        <RouteContainer isProtected path="/faq/:centro_custo_id">
          <FaqViewContainer />
        </RouteContainer>
        <RouteContainer isProtected path="/payment/:centro_custo_id">
          <PaymentViewContainer />
        </RouteContainer>
        <RouteContainer isProtected path="/finish/:centro_custo_id">
          <FinishedViewContainer />
        </RouteContainer>
        <RouteContainer isProtected path="/listing/:centro_custo_id">
          <ListingViewContainer />
        </RouteContainer>
      </Switch>
    </Router>
  );
};

export default AppContainer;
