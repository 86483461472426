import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  actions: {
    textAlign: 'right',
  },
  btnBoleto: {
    height: 70,
    borderRadius: 40,
    background: 'transparent',
    color: '#919191',
    fontSize: 30,
    fontWeight: 100,
    border: '2px solid #c0c0c0',
    marginTop: 10,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '85%',
    },
  },
  btnConfirm: {
    height: 70,
    borderRadius: 40,
    background: 'transparent',
    color: '#919191',
    fontSize: 30,
    fontWeight: 100,
    border: '2px solid #c0c0c0',
    marginTop: 10,
    width: '60%',
    [theme.breakpoints.down('sm')]: {
      width: '85%',
    },
  },
  btnIconModePayment: {
    border: ' 2px solid',
    borderRadius: 10,
  },
  btnSaveFinished: {
    padding: 10,
    border: '2px solid #14147a',
    borderRadius: 10,
    marginLeft: 10,
  },
  btnSaveIcon: {
    color: '#172382',
    size: 40,
  },
  btnSavePayment: {
    marginTop: 20,
    color: 'rgba(0, 0, 0, 0.87)',
    backgroundColor: '#e0e0e0',
  },
  container: {
    background: '#ededed',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    minHeight: '100vh',
    '& .MuiFormControlLabel-root': {
      marginLeft: 0,
      marginRight: 0,
      width: '100%',
    },
  },
  containerFinished: {
    background: '#ededed',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    minHeight: '100vh',
    [theme.breakpoints.down('sm')]: {
      '& .MuiFormControlLabel-root': {
        marginLeft: 0,
        marginRight: 0,
        marginBottom: '10%',
      },
    },
  },
  dadosRecibo: {
    padding: 10,
    boxShadow: 'none',
    borderRadius: 0,
    borderBottom: '2px solid #141b76',
  },
  descRecibo: {
    padding: 10,
    boxShadow: 'none',
    borderRadius: 0,
  },
  descReciboBody: {
    '& div': {
      fontWeight: 600,
    },
  },
  descReciboHeader: {
    '& p': {
      fontSize: 30,
    },
  },
  fields: {
    width: '100%',
  },
  formPerson: {
    display: 'flex',
    flexDirection: 'column',
    '& .MuiButtonBase-root': {
      width: '20%',
      alignSelf: 'flex-end',
    },
  },
  grid: {
    color: 'black',
    padding: '100px 20px',
    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
      marginLeft: 350,
    },
    [theme.breakpoints.down('sm')]: {
      padding: '75px 0 140px 0',
      margin: 0,
    },
  },
  gridButtons: {
    '@media print': {
      display: 'none',
    },
  },
  gridCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      '& .rccs__card': {
        width: '80%',
        height: 150,
      },
    },
  },
  gridContainer: {
    display: 'flex',
    marginBottom: 10,
    justifyContent: 'center',
    '& .MuiTypography-root': {
      color: '#2772B5',
      textAlign: 'center',
    },
  },
  gridDataCart: {
    padding: 20,
  },
  gridRecibo: {
    '@media print': {
      display: 'block',
    },
  },
  headerRecibo: {
    padding: 10,
    boxShadow: 'none',
    borderRadius: 0,
    borderBottom: '6px solid #141b76',
  },
  iconArrowNext: {
    '& .material-icons': {
      fontSize: 40,
    },
  },
  infoHeader: {
    '& p': {
      fontSize: 14,
      margin: 0,
    },
  },
  infoNome: {
    fontSize: 25,
  },
  root: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexGrow: 1,
    '& .MuiTypography-h5': {
      fontSize: 17,
    },
  },
  tabsMobile: {
    width: '100%',
    margin: 10,
    background: '#fff',
    height: 'auto',
  },
  textFatura: {
    fontSize: 40,
    paddingTop: 5,
  },
  textHeader: {
    color: '#919191',
    [theme.breakpoints.up('sm')]: {
      fontSize: 30,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
  },
  textPaymentMode: {
    margin: '15px 0',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      margin: 0,
    },
  },
  txtFooter: {
    textAlign: 'center',
  },
  txtVlTotal: {
    fontSize: 20,
    paddingTop: 20,
    textAlign: 'right',
  },
  vlDesc: {
    textAlign: 'right',
  },
  vlTotal: {
    fontSize: 35,
    fontWeight: 900,
    textAlign: 'right',
  },
}));

export default useStyles;
