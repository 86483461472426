import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Config, useFetchApi, useRedirect } from '@itarget/shared';
import { useFormik } from 'formik';
import { toast } from '../../redux';

import RecoveryView from './views/RecoveryView';

const RecoveryContainer = (props) => {
  const dispatch = useDispatch();
  const { goTo } = useRedirect();
  const { centro_custo_id } = useParams();
  const language = useSelector((state) => state.auth.language);
  const selectedLang = useSelector((state) => state.language.lang);

  const {
    data: recoveryPassword,
    fetchData: fetchRecoveryPassword,
    error,
  } = useFetchApi(Config.baseURL + `api/${selectedLang}/recovery-password`, 'POST');

  useEffect(() => {
    if (error !== null) {
      dispatch(
        toast.actions.enqueue({
          content: error,
          severity: 'error',
        }),
      );
    }
    if (recoveryPassword !== null) {
      dispatch(
        toast.actions.enqueue({
          content: recoveryPassword.message,
          severity: 'success',
        }),
      );
      goTo(`change-password/${centro_custo_id}`);
    }
  }, [recoveryPassword, error]);

  const onSubmit = async (document) => {
    fetchRecoveryPassword(JSON.stringify(document));
  };

  const formik = useFormik({
    initialValues: { login: '', centro_custo_id: centro_custo_id },
    enableReinitialize: true,
    onSubmit,
  });

  return (
    <RecoveryView
      props={props}
      centro_custo_id={centro_custo_id}
      language={language}
      formik={formik}
      goTo={goTo}
    />
  );
};

export default RecoveryContainer;
