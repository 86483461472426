import { makeStyles } from '@material-ui/core';
import { Config } from '@itarget/shared';

const useStyles = makeStyles((theme) => ({
  btnUpload: {
    marginTop: 10,
  },
  buttonPayment: {
    color: '#e8308a',
    backgroundColor: 'white',
    '& .MuiButton-label': {
      display: 'flex',
      flexDirection: 'column',
      fontSize: 8,
    },
    '& .MuiSvgIcon-root': {
      fontSize: 32,
      marginRight: 0,
    },
  },
  cardIcon: {
    fontSize: 50,
  },
  comprovante: {
    marginBottom: 40,
  },
  container: {
    background: '#ededed',
    [theme.breakpoints.down('sm')]: {
      '& .MuiFormControlLabel-root': {
        width: '100%',
        marginLeft: 0,
        marginRight: 0,
        marginBottom: '10%',
      },
    },
    [theme.breakpoints.down('md')]: {
      '& .MuiFormControlLabel-root': {
        width: '100%',
        marginLeft: 0,
        marginRight: 0,
        marginBottom: '10%',
      },
    },
    [theme.breakpoints.down('lg')]: {
      '& .MuiFormControlLabel-root': {
        width: '100%',
        height: '100%',
        marginLeft: 0,
        marginRight: 0,
        marginBottom: '10%',
      },
    },
  },
  divValue: {
    margin: '0 40px',
    backgroundColor: '#EEE',
    padding: 10,
    borderRadius: '0 0 10px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  divValueDesc: {
    fontWeight: 100,
    fontSize: '35px !important',
    marginTop: -5,
  },
  divValueHorizontal: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    zIndex: 999,
    backgroundColor: '#ededed',
    alignItems: 'center',
  },
  field: {
    width: '100%',
  },
  formCards: {
    width: '97%',
    '& .MuiPaper-root': {
      width: '100%',
    },
  },
  grid: {
    padding: '120px 3%',
    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
      marginLeft: 350,
    },
    [theme.breakpoints.down('sm')]: {
      padding: '100px 20px',
      marginBottom: 150,
    },
  },
  horizontal: {
    width: '100%',
    bottom: '0',
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'fixed',
    zIndex: 9999,
    '& .MuiPaper-root': {
      width: '100%',
      margin: 0,
    },
  },
  msnUpload: {
    fontSize: 25,
    color: '#969391',
  },
  root: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexGrow: 1,
    '& .MuiTypography-h5': {
      fontSize: 17,
    },
  },
  textHeader: {
    color: '#919191',
    fontWeight: 100,
    marginBottom: 40,
    [theme.breakpoints.up('sm')]: {
      fontSize: 30,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
      textAlign: 'center',
    },
  },
  textPriceHorizontal: {
    margin: '10px 20px',
    fontSize: 40,
    fontWeight: 300,
  },
  vertical: (props) => ({
    width: 350,
    height: '100vh',
    backgroundColor: '#050505',
    display: 'flex',
    alignItems: 'flex-start',
    position: 'fixed',
    justifyContent: 'center',
    left: 0,
    paddingTop: 195,
    [theme.breakpoints.down('md')]: {
      paddingTop: 220,
    },
    background: `url("/assets/images/clients/${Config.client}/${
      props.routeParams.centro_custo_id
    }/${props.language ? props.language : 'pt-br'}/bgCursos.jpeg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  }),
  entityAttachment: {
    marginTop: 10,
    cursor: 'pointer',
  },
  accordion: {
    margin: '20px 40px !important',
    borderRadius: '10px 10px 0 0 !important',
  },
  accordionHeader: {
    backgroundColor: '#131b77',
    margin: 0,
    minHeight: '35px !important',
    borderRadius: '10px 10px 0 0',
    '& .MuiSvgIcon-root': {
      color: '#fff',
    },
    '& .MuiAccordionSummary-content': {
      margin: '12px 0 !important',
    },
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    maxHeight: '40vh',
    overflowX: 'hidden',
  },
  heading: {
    color: '#fff',
    fontWeight: 'bold',
    margin: 0,
    fontSize: 22,
    marginTop: -6,
  },
  textItem: {
    display: 'flex',
    alignItems: 'center',
    margin: 5,
  },
  iconItem: {
    fontSize: '1rem',
    margin: 5,
  },
  dividerItem: {
    width: '100%',
  },
}));

export default useStyles;
