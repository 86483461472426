import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { payment, course, toast } from '../../../redux';
import useStyles from './style';
import {
  Typography,
  Icon,
  Button,
  Hidden,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Dialog,
  DialogTitle,
  DialogActions,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {
  formatMoney,
  Config,
  useFetchApi,
  configurator,
  useRedirect,
  subscriptionRequest as subscriptionRequestShared,
  Translator,
  ModalCategoryReceipt,
} from '@itarget/shared';
import AccordionCourse from '../../../components/AccordionCourse';

const DiscountViewContainer = (props) => {
  const { goTo } = useRedirect();
  const dispatch = useDispatch();
  const routeParams = useParams();
  const language = useSelector((state) => state.course.language);
  const settingDollar = useSelector((state) => state.payment.settingDollar);
  const classes = useStyles({ routeParams, language });

  const [valueVoucher, setValueVoucher] = useState();
  const [voucher, setVoucher] = useState();
  const [statusPayment, setStatusPayment] = useState('goToPayment');
  const [subscription_, setSubscription_] = useState([]);
  const [onPaymentStatus, setOnPaymentStatus] = useState(false);
  const [hideVoucher, setHideVoucher] = useState(false);
  const [openConfirmUnsubscribe, setOpenConfirmUnsubscribe] = useState(false);
  const [dataInscricoesCurr, setDataInscricoesCurr] = useState();
  const [openModalReceipt, setOpenModalReceipt] = useState(false);
  const [dataContact, setDataContact] = useState();
  const [loadingBtn, setLoadingBtn] = useState(false);
  const selectedCourse = useSelector((state) => state.course.courseSelected);
  const user = useSelector((state) => state.auth.user);
  const inPayment = useSelector((state) => state.payment.inPayment);
  const subscription = useSelector((state) => state.payment.subscription);
  const total = useSelector((state) => state.payment.total);
  const voucherReducer = useSelector((state) => state.payment.voucher);
  const codVoucherReducer = useSelector((state) => state.payment.codVoucher);
  const statusReceipt = useSelector((state) => state.payment.statusReceipt);
  const selectedLang = useSelector((state) => state.language.lang);
  const settings = useSelector((state) => state.setting.settings);

  const viewPayment = useState(props.viewPayment === false ? false : true);

  let labelPayment = Translator({ transRef: 'translation.PAGAMENTO' }).props
    .children;
  let labelConfirm = Translator({ transRef: 'translation.CONFIRMAR' }).props
    .children;
  let labelApply = Translator({ transRef: 'translation.APLICAR VOUCHER' }).props
    .children;

  useEffect(() => {
    setDataContact(
      settings?.find((r) => r.codigo === 9)?.valor_referencia.split('|'),
    );
  }, [settings]);

  const onValidSubscription = () => {
    dispatch(
      payment.actions.setAllSubscription([
        ...subscription_,
        subscriptionRequest.data,
      ]),
    );
    dispatch(
      toast.actions.enqueue({
        content: subscriptionRequest.message,
        severity: 'success',
      }),
    );
    setSubscription_([...subscription_, subscriptionRequest.data]);
  };

  const { onSubscribe, subscription: subscriptionRequest } =
    subscriptionRequestShared({
      user,
      selectedCourse: selectedCourse,
      onValid: onValidSubscription,
      subscriptionRedux: payment,
      toast: toast,
    });

  const { data: setting, fetchData: fetchSetting } = useFetchApi(
    Config.baseURL +
    `api/${selectedLang}/setting?centro_custo_id=${routeParams.centro_custo_id}&${configurator.DISCOUNT}`,
  );

  const {
    data: voucherRequest,
    isLoading: isLoadingListVoucher,
    error: erroListVoucher,
    fetchData: fetchListVoucher,
  } = useFetchApi();

  const {
    isLoading: isLoadingSubscriptionPut,
    fetchData: fetchSubscriptionPut,
  } = useFetchApi(Config.baseURL + `api/${selectedLang}/subscription`, 'PUT');

  const {
    data: voucherPut,
    error: erroVoucherPut,
    fetchData: fetchVoucherPut,
  } = useFetchApi(
    Config.baseURL +
    `api/${selectedLang}/finance/voucher/${valueVoucher}/apply`,
    'PUT',
  );

  const { isLoading: isLoadingCancelPayment, fetchData: fetchCancelPayment } =
    useFetchApi(
      Config.baseURL +
      `api/${selectedLang}/finance/account-receive/cancel-payment`,
      'PUT',
    );

  useEffect(() => {
    if (setting && setting.data.length > 0) {
      const voucher = setting.data.filter((res) => res.codigo === 379)[0];
      setHideVoucher(voucher.valor_referencia === 'N');
    } else {
      setHideVoucher(true);
    }
  }, [setting]);

  useEffect(() => {
    if (erroVoucherPut !== null) {
      dispatch(
        toast.actions.enqueue({
          content: erroVoucherPut,
          severity: 'error',
        }),
      );
    }
    if (voucherPut !== null) {
      dispatch(
        toast.actions.enqueue({
          content: voucherPut.message,
          severity: 'success',
        }),
      );
    }
  }, [erroVoucherPut, voucherPut]);

  useEffect(() => {
    fetchSetting();
    if (voucherReducer && voucherReducer.id) {
      setVoucher(voucherReducer);
    }
    if (codVoucherReducer) {
      setValueVoucher(codVoucherReducer);
    }
    if (!props.paymentView && !props.viewFinished) {
      setValueVoucher('');
      dispatch(payment.actions.cleanSubscription());
    }
  }, []);

  useEffect(() => {
    if (voucherRequest) {
      setVoucher(voucherRequest);
    }
  }, [voucherRequest]);

  useEffect(() => {
    if (valueVoucher && !voucher) {
      setStatusPayment('goApplyVoucher');
    }
    if (valueVoucher && voucher && total !== 0) {
      setStatusPayment('goToPayment');
    }
    if (voucher && total === 0) {
      setStatusPayment('goToPayment');
    }
    if (!valueVoucher && total !== 0) {
      setStatusPayment('goToPayment');
    }

    if (!valueVoucher && total === 0) {
      setStatusPayment('goToConfirmSubscription');
    }
    if (props.paymentView && voucher && total !== 0) {
      setStatusPayment('disabled');
    }
    if (props.paymentView && voucher && total === 0) {
      setStatusPayment('goToConfirmSubscriptionVoucher');
    }
    if (props.paymentView && !voucher) {
      setStatusPayment('goApplyVoucher');
    }
  }, [valueVoucher, voucher, total]);

  useEffect(() => {
    soma();
  }, [selectedCourse]);

  useEffect(() => {
    if (inPayment) {
      dispatch(payment.actions.setInpayment());
    }
  }, [inPayment]);

  useEffect(() => {
    if (erroListVoucher !== null) {
      dispatch(
        toast.actions.enqueue({
          content: erroListVoucher,
          severity: 'error',
        }),
      );
    }
  }, [erroListVoucher]);

  useEffect(() => {
    if (voucher && selectedCourse.length > 0) {
      const currDiscount =
        voucher?.data?.agenda_atividade_id
          ? selectedCourse.filter(
            (r) => r?.aga_id === voucher.data.agenda_atividade_id
              || r?.agenda_atividade_id === voucher.data.agenda_atividade_id
          )
          : selectedCourse;
      const descount = voucher?.data?.desconto;
      const descountType = voucher?.data?.tipo_desconto;
      const courseFilter = selectedCourse.filter((r) => r === currDiscount[0])[0];

      if (descountType === 'P') {
        const valor =
          courseFilter.valor -
          courseFilter.valor * (parseFloat(descount) / 100);
        dispatch(
          course.actions.updateSelectCourse({
            course: courseFilter,
            element: 'valor',
            value: valor,
          }),
        );
      }
      if (descountType === 'V') {
        const valor = courseFilter.valor - parseFloat(descount);
        dispatch(
          course.actions.updateSelectCourse({
            course: courseFilter,
            element: 'valor',
            value: valor,
          }),
        );
      }

      dispatch(payment.actions.setCodVoucher(valueVoucher));
      dispatch(payment.actions.setVoucher(voucher && voucher.data));
    }
  }, [voucher, subscription]);

  useEffect(() => {
    if (
      subscription_.length > 0 &&
      subscription_.length === selectedCourse.length
    ) {
      var subscriptionFilter = subscription_.map((res) => {
        var agenda_atividade_id =
          selectedCourse.filter(
            (course) => course.plp_id === res.plano_pagamento_id,
          )[0] &&
          selectedCourse.filter(
            (course) => course.plp_id === res.plano_pagamento_id,
          )[0].aga_id;
        return { agenda_atividade_id, inscricao_id: res.id };
      });
      if (valueVoucher) {
        fetchVoucherPut(
          JSON.stringify({
            origem_pessoa_id: user.data.id,
            centro_custo_id: parseInt(routeParams.centro_custo_id),
            amount: total,
            subscription: subscriptionFilter,
          }),
        );
      }
      if (onPaymentStatus) {
        redirect();
      }
    }
  }, [subscription_]);

  const redirect = () => {
    if (statusPayment === 'goToPayment') {
      goTo(`payment/${routeParams.centro_custo_id}`);
      return false;
    }

    if (statusPayment === 'goToConfirmSubscription' || statusPayment === 'goToConfirmSubscriptionVoucher') {
      goTo(`listing/${routeParams.centro_custo_id}`);
    }
  };

  useEffect(() => {
    if (subscriptionRequest?.data) {
    }
  }, [subscriptionRequest]);

  const soma = () => {
    var numeros =
      selectedCourse && selectedCourse.map((row) => parseFloat(row.valor)).reduce(function (total, numero) {
        return total + numero
      }, 0)
    var total = numeros.reduce(function (total, numero) {
      return total + numero;
    }, 0);
    dispatch(payment.actions.setTotal(total));
  };

  const onPayment = async () => {

    if (!statusReceipt && user?.data?.associado !== true) {
      setOpenModalReceipt(true);
      return;
    }
    if (selectedCourse.length === 0) {
      dispatch(
        toast.actions.enqueue({
          content: 'Selecione um curso',
          severity: 'error',
        }),
      );
      return false;
    }
    for (var i = 0; i < selectedCourse.length; i++) {
      await onSubscribe(selectedCourse[i]);
    }
    if (subscription_.length > 0) {
      setOnPaymentStatus(true);
      return false;
    }
    if (statusPayment === 'goToConfirmSubscriptionVoucher') {

      if (valueVoucher) {
        await fetchVoucherPut(
          JSON.stringify({
            origem_pessoa_id: user.data.id,
            centro_custo_id: parseInt(routeParams.centro_custo_id),
            amount: total,
            subscription: selectedCourse,
          }),
        );
      }
    }

    setLoadingBtn(true);
    setTimeout(() => {
      redirect();
      setLoadingBtn(false);
    }, 3000);
  };

  const handleCloseCourse = (row) => {
    var dataInscricoes_ =
      props.dataInscricoes &&
      props.dataInscricoes.data.filter(
        (res) => res.agenda_atividade_id === row.aga_id,
      )[0];

    if (dataInscricoes_) {
      setOpenConfirmUnsubscribe(true);
      setDataInscricoesCurr(dataInscricoes_);
      return;
    }

    const currSubscription =
      subscription &&
      subscription.filter((r) => r.plano_pagamento_id === row.plp_id);
    dispatch(
      course.actions.setSelectCourse(
        selectedCourse.filter((res) => res !== row),
      ),
    );
    dispatch(payment.actions.resetSubscription(currSubscription[0]));
  };

  const onClickUnsubscribe = async () => {
    await fetchCancelPayment(
      JSON.stringify({
        id: dataInscricoesCurr.conta_receber_id,
      }),
      Config.baseURL +
      `api/${selectedLang}/finance/account-receive/cancel-payment`,
    );
    await fetchSubscriptionPut(
      JSON.stringify({
        pessoa_id: user.data && user.data.id,
        id: dataInscricoesCurr.inscricao_uuid,
        status_inscricao: 3,
      }),
      Config.baseURL +
      `api/${selectedLang}/subscription/${dataInscricoesCurr.inscricao_uuid}`,
    );
    setOpenConfirmUnsubscribe(false);
    props.refreshListCourses();
  };

  const handleClose = () => {
    setOpenConfirmUnsubscribe(false);
  };

  const onChangeVoucher = (event) => {
    setValueVoucher(event.target.value);
  };

  const confirmVoucher = () => {
    if (selectedCourse.length === 0) {
      dispatch(
        toast.actions.enqueue({
          content: 'Selecione um curso',
          severity: 'error',
        }),
      );
      return false;
    }
    fetchListVoucher(
      null,
      Config.baseURL +
      `api/${selectedLang}/finance/voucher/${valueVoucher}/valid?origem_pessoa_id=${user.data.id}`,
    );
  };

  const ButtonConfirm = () => {
    var name = hideVoucher
      ? statusPayment === 'goToConfirmSubscription'
        ? labelConfirm
        : labelPayment
      : statusPayment === 'goApplyVoucher'
        ? labelApply
        : statusPayment === 'goToConfirmSubscription'
          ? labelConfirm
          : labelPayment;

    return loadingBtn || isLoadingListVoucher ? (
      <CircularProgress color="secondary" style={{ marginRight: 10 }} />
    ) : (hideVoucher && selectedCourse.length === 0) ||
      (hideVoucher && props.paymentView) ? (
      <></>
    ) : (
      <Button
        variant="outlined"
        className={classes.buttonPayment}
        onClick={() =>
          statusPayment === 'goApplyVoucher' ? confirmVoucher() : onPayment()
        }
        disabled={statusPayment === 'disabled'}
      >
        <Icon>
          {statusPayment === 'goApplyVoucher' ||
            statusPayment === 'goToConfirmSubscription'
            ? 'check'
            : 'shopping_cart'}
        </Icon>
        <Translator transRef={`translation.${name}`} />
      </Button>
    );
  };

  const ContactsComponent = () => {
    return (
      <Accordion defaultExpanded={false} className={classes.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          classes={{ root: classes.accordionHeader }}
        >
          <Typography className={classes.heading}>
            <Translator transRef={'translation.Contato'} />
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <div className={classes.detailCourse}>
            <Typography variant="caption" className={classes.textItem}>
              <Icon className={classes.iconItem}>phone</Icon> {dataContact?.[0]}
            </Typography>
            <Divider className={classes.dividerItem} />
            <Typography className={classes.textItem} variant="caption">
              <Icon className={classes.iconItem}>email</Icon> {dataContact?.[1]}
            </Typography>
            <Divider className={classes.dividerItem} />
            <Typography
              className={classes.textItem}
              style={{ cursor: 'pointer' }}
              variant="caption"
              onClick={() => window.open(dataContact?.[2], '_blank')}
            >
              <Icon className={classes.iconItem}>link</Icon>
              {dataContact?.[2]}
            </Typography>
          </div>
        </AccordionDetails>
      </Accordion>
    );
  };

  return (
    <>
      <Dialog
        open={openConfirmUnsubscribe}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Translator
            transRef={'translation.Deseja cancelar a sua inscrição?'}
          />
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            <Translator transRef={'translation.Voltar'} />
          </Button>
          <Button onClick={onClickUnsubscribe} color="primary" autoFocus>
            {isLoadingCancelPayment || isLoadingSubscriptionPut ? (
              <CircularProgress color="secondary" style={{ marginRight: 10 }} />
            ) : (
              'Cancelar inscrição'
            )}
          </Button>
        </DialogActions>
      </Dialog>
      {openModalReceipt && (
        <ModalCategoryReceipt
          centerCostId={routeParams.centro_custo_id}
          open={openModalReceipt}
          setOpen={setOpenModalReceipt}
          toast={toast}
          payment={payment}
        />
      )}
      <Hidden smDown>
        <div className={classes.vertical}>
          {viewPayment ? (
            <div>
              <AccordionCourse
                courses={selectedCourse}
                handleCloseCourse={handleCloseCourse}
                onChangeVoucher={onChangeVoucher}
                valueVoucher={valueVoucher}
                hideVoucher={hideVoucher}
              />
              <div className={classes.divValue}>
                <Typography variant="h5" className={classes.divValueDesc}>
                  R${' '}
                  {total > 0
                    ? formatMoney.toReal(total) +
                    (settingDollar?.filter((sd) => sd.codigo === 683)
                      ?.length > 0 &&
                      ' /$' +
                      formatMoney.toReal(
                        total /
                        settingDollar?.filter(
                          (sd) => sd.codigo === 683,
                        )[0].valor_referencia,
                      ))
                    : '0,00'}
                </Typography>
                <ButtonConfirm />
              </div>
              <ContactsComponent />
            </div>
          ) : (
            <></>
          )}
        </div>
      </Hidden>
      <Hidden mdUp>
        <div className={classes.horizontal}>
          <AccordionCourse
            courses={selectedCourse}
            handleCloseCourse={handleCloseCourse}
            onChangeVoucher={onChangeVoucher}
            valueVoucher={valueVoucher}
            hideVoucher={hideVoucher}
          />
          <div className={classes.divValueHorizontal}>
            <Typography variant="h5" className={classes.textPriceHorizontal}>
              R$
              {total > 0
                ? formatMoney.toReal(total) +
                (settingDollar?.filter((sd) => sd.codigo === 683)?.length >
                  0 &&
                  ' /$' +
                  formatMoney.toReal(
                    total /
                    settingDollar?.filter((sd) => sd.codigo === 683)[0]
                      .valor_referencia,
                  ))
                : '0,00'}
            </Typography>
            <ButtonConfirm />
          </div>
        </div>
      </Hidden>
    </>
  );
};

export default DiscountViewContainer;
