import React from 'react';
import { useSelector } from 'react-redux';
import { Translator } from '@itarget/shared';

import {
  Typography,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  Button,
  Icon,
} from '@material-ui/core';
import useStyles from './style';

const AuthorizeAccess = (props) => {
  const classes = useStyles();
  const user = useSelector((state) => state.auth.user);

  return (
    <div className={classes.root}>
      <Grid className={classes.gridContainer}>
        <Typography variant="h4" className={classes.text} gutterBottom>
          <Translator transRef={`translation.Boa tarde, ${user.data && user.data.nome}`} />
        </Typography>
        <Typography variant="h4" className={classes.textInfo} gutterBottom>
          <Translator transRef={'translation.Aprove nossos termos de uso para prosseguir'} />
        </Typography>
        <Grid className={classes.grid}>
          <FormControlLabel
            className={classes.fieldCheckbox}
            control={
              <Checkbox
                color="default"
                required={true}
                inputProps={{ 'aria-label': 'primary checkbox' }}
                checked={props.checked}
                onChange={props.handleChange}
              />
            }
            required={true}
            name={'autorizou_acesso'}
          />
          <Typography component="caption">
            <Translator transRef={'translation.Eu li e concordo com a '} />
            <Link className={classes.link}>
            <Translator transRef={'translation.politica de privacidade'} />
            </Link>
          </Typography>
        </Grid>
        <Grid className={classes.gridBtn}>
          <Button
            type="submit"
            variant="contained"
            onClick={props.onClick}
            className={classes.btnSubmit}
          >
            <Icon style={{ fontSize: 35, margin: '0 15px' }}>double_arrow</Icon>
            <Translator transRef={'translation.Prosseguir'} />
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default AuthorizeAccess;
