import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import queryString from 'query-string';
import 'react-credit-cards/es/styles-compiled.css';
import {
  Grid,
  Typography,
  Container,
  Tab,
  Box,
  withStyles,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
  Config,
  useFetchApi,
  debounce,
  PaymentContainer,
  useRedirect,
  Translator,
} from '@itarget/shared';

import Layout from '../../../components/Layout';
import DiscountViewContainer from '../Course/DiscountViewContainer';

import { toast, payment } from '../../../redux';
import useStyles from './style';
import 'react-credit-cards/es/styles-compiled.css';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const AntTab = withStyles((theme) => ({
  root: {
    fontSize: 10,
  },
  labelIcon: {
    minHeight: 53,
    '& $wrapper > *:first-child': {
      marginBottom: 0,
    },
  },
  wrapper: {
    flexDirection: 'row',
    letterSpacing: '1px',
    textTransform: 'uppercase',
    '& svg, .material-icons': {
      fontSize: 20,
      marginRight: 8,
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const PaymentViewContainer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const routeParams = useParams();
  const { goTo } = useRedirect();
  const [loadOptions, setLoadOptions] = useState();
  const [validateCep, setValidateCep] = useState('');
  const [value, setValue] = useState(0);
  const [dataFormPayment, setDataFormPayment] = useState();
  const [incIds, setIncIds] = useState();

  const [formData, setFormData] = useState();
  const [fieldsPerson, setFieldsPerson] = useState([]);
  const [fieldsAddress, setFieldsAddress] = useState([]);

  const subscription = useSelector((state) => state.payment.subscription);
  const user = useSelector((state) => state.auth.user);
  const total = useSelector((state) => state.payment.total);
  const paymentType = useSelector((state) => state.payment.type);
  const selectedLang = useSelector((state) => state.language.lang);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const {
    data: formPerson,
    error: errorPerson,
    fetchData: fetchFormPerson,
  } = useFetchApi(Config.baseURL + `api/${selectedLang}/form/${user.data.id}`, 'PUT');

  const { data: municipio, fetchData: fetchMunicipio } = useFetchApi();

  const { data: cep, fetchData: fetchCep } = useFetchApi();

  const {
    data: formPayment,
    isLoading: isLoadingFormPayment,
    fetchData: fetchFormPayment,
  } = useFetchApi(
    Config.baseURL +
      `api/${selectedLang}/form?centro_custo_id=${routeParams.centro_custo_id}&tipo_interface_id=103&pessoa_id=${user.data.id}&exibir_edicao=S`,
  );

  useEffect(() => {
    if (formPayment) {
      setFieldsPerson(formPayment.tabs.filter((row) => row.id === 1)[0].fields);
      setFieldsAddress(
        formPayment.tabs.filter((row) => row.id === 2)[0].fields,
      );
      setFormData(formPayment.data);
    }
  }, [formPayment]);

  const onSubmitPerson = (e) => {
    const value = {};
    value.pessoa = formikPerson.values.pessoa;
    value.centro_custo_id = routeParams.centro_custo_id;
    value.tipo_interface_id = 103;
    value.id = user.data.id;

    if (Object.values(formikPerson.values.telefone)[0]) {
      value.telefone = [];
      for (
        let i = 0;
        i < Object.keys(formikPerson.values.telefone).length;
        i++
      ) {
        if (
          formikPerson.values.telefone[
            Object.keys(formikPerson.values.telefone)[i]
          ]
        ) {
          value.telefone[i] = {
            [Object.keys(formikPerson.values.telefone)[i]]:
              formikPerson.values.telefone[
                Object.keys(formikPerson.values.telefone)[i]
              ],
          };
        }
      }
    }

    fetchFormPerson(JSON.stringify(value));
    e.preventDefault();
  };

  const onSubmitAddress = (e) => {
    e.centro_custo_id = routeParams.centro_custo_id;
    e.tipo_interface_id = 103;
    e.id = user.data.id;
    e.single_save = true;
    e.endereco = [e.endereco];
    if (dataFormPayment[0] && dataFormPayment[0].id) {
      fetchFormAdressUpdate(JSON.stringify(e));
    } else {
      fetchFormAdressPost(JSON.stringify(value));
    }
    formikAddress.values.endereco = e.endereco[0];
  };

  const formikPerson = useFormik({
    initialValues: {},
    enableReinitialize: true,
    onSubmit: onSubmitPerson,
  });

  const formikAddress = useFormik({
    initialValues: {},
    enableReinitialize: false,
    onSubmit: onSubmitAddress,
  });

  const setValuesFormik = (data, fields, formik_) => {
    const array = Object.entries(data).filter((row) => {
      if (row[1] !== null) {
        return row;
      }
    });
    for (let i = 0; i < array.length; i++) {
      if (fields.map((row) => row.name).includes(array[i][0])) {
        let belongsTo = fields.filter((row) => row.name === array[i][0])[0]
          .belongs_to;
        formik_.setFieldValue(`${belongsTo}[${array[i][0]}]`, array[i][1]);
      }

      if (
        array[i][0] === 'telefone' &&
        fields.find((row) => row.belongs_to === 'telefone')
      ) {
        const fieldPhone = fields.filter(
          (row) => row.belongs_to === 'telefone',
        );
        for (let j = 0; j < fieldPhone.length; j++) {
          if (fieldPhone[j].name === 'celular') {
            formik_.setFieldValue(
              `${fieldPhone[j].belongs_to}[${fieldPhone[j].name}]`,
              array[i][1].find((row) => row.tipo === 'C') &&
                array[i][1].find((row) => row.tipo === 'C').fone,
            );
          }
          if (
            fieldPhone[j].name === 'residencial' &&
            array[i][1].find((row) => row.tipo === 'F')
          ) {
            formik_.setFieldValue(
              `${fieldPhone[j].belongs_to}[${fieldPhone[j].name}]`,
              array[i][1].find((row) => row.tipo === 'F').fone,
            );
          }
        }
      }
    }
  };

  useEffect(() => {
    if (formData) {
      setValuesFormik(formData, fieldsPerson, formikPerson);
    }
    if (formData && formData.endereco && formData.endereco.length > 0) {
      setValuesFormik(formData.endereco[0], fieldsAddress, formikAddress);
    }
  }, [formData]);

  const {
    data: formAdressPost,
    error: errorAdressPost,
    fetchData: fetchFormAdressPost,
  } = useFetchApi(Config.baseURL + `api/${selectedLang}/form`, 'POST');

  useEffect(() => {
    if (errorAdressPost !== null) {
      dispatch(
        toast.actions.enqueue({
          content: errorAdressPost,
          severity: 'error',
        }),
      );
    }
    if (formAdressPost !== null) {
      setDataFormPayment({
        ...dataFormPayment,
        id: formAdressPost.data.enderecos[0],
      });
      formikAddress.setFieldValue(
        'endereco[id]',
        formAdressPost.data.enderecos[0],
      );
      dispatch(
        toast.actions.enqueue({
          content: formAdressPost.message,
          severity: 'success',
        }),
      );
    }
  }, [formAdressPost, errorAdressPost]);

  const {
    data: formAdressUpdate,
    error: errorAdressUpdate,
    fetchData: fetchFormAdressUpdate,
  } = useFetchApi(
    Config.baseURL +
      `api/${selectedLang}/form/${
        formikAddress.values.endereco && formikAddress.values.endereco.id
      }`,
    'PUT',
  );

  useEffect(() => {
    if (errorAdressUpdate !== null) {
      dispatch(
        toast.actions.enqueue({
          content: errorAdressUpdate,
          severity: 'error',
        }),
      );
    }
    if (formAdressUpdate !== null) {
      dispatch(
        toast.actions.enqueue({
          content: formAdressUpdate.message,
          severity: 'success',
        }),
      );
    }
  }, [formAdressUpdate, errorAdressUpdate]);

  const {
    data: accountReceive,
    isLoading: isLoadingAccountReceive,
    fetchData: fetchAccountReceive,
  } = useFetchApi(
    Config.baseURL +
      `api/${selectedLang}/finance/account-receive?status[]=1&status[]=2&` +
      queryString.stringify(
        {
          inscricao_id: incIds,
        },
        { arrayFormat: 'bracket' },
      ),
  );

  useEffect(() => {
    if (subscription.length > 0) {
      setIncIds(
        subscription.map((res, i) => {
          if (res.id) {
            return res.id;
          } else if (res.inscricao_uuid) {
            return res.inscricao_uuid;
          }
        }),
      );
    }
  }, [subscription]);

  useEffect(() => {
    if (incIds && incIds.length > 0) {
      fetchAccountReceive();
    }
  }, [incIds]);

  useEffect(() => {
    if (errorPerson !== null) {
      dispatch(
        toast.actions.enqueue({
          content: errorPerson,
          severity: 'error',
        }),
      );
    }
    if (formPerson !== null) {
      dispatch(
        toast.actions.enqueue({
          content: formPerson.message,
          severity: 'success',
        }),
      );
    }
  }, [formPerson, errorPerson]);

  useEffect(() => {
    fetchFormPayment();
  }, []);

  useEffect(() => {
    if (!formPayment?.tabs) {
      return;
    }
    setDataFormPayment(
      formPayment.data &&
        formPayment.data.endereco &&
        formPayment.data.endereco &&
        formPayment.data.endereco,
    );
    setLoadOptions(
      formPayment.tabs
        .filter((r) => r.id === 2)[0]
        .fields.filter((r) => r.name === 'uf_id')[0].loadOptions,
    );
  }, [formPayment]);

  useEffect(() => {
    if (fieldsAddress.length > 0) {
      setValidateCep(fieldsAddress.filter((r) => r.name === 'cep')[0].validate);
    }
  }, [fieldsAddress]);

  useEffect(() => {
    if (cep && cep.data) {
      formikAddress.setFieldValue('endereco[logradouro]', cep.data.logradouro);
      formikAddress.setFieldValue('endereco[bairro]', cep.data.bairro);
      formikAddress.setFieldValue(
        'endereco[municipio_id]',
        cep.data.municipio_id,
      );
      formikAddress.setFieldValue(
        'endereco[tipo_logradouro_id]',
        cep.data.tipo_logradouro_id,
      );
      formikAddress.setFieldValue('endereco[uf_id]', cep.data.uf_id);
      formikAddress.setFieldValue('endereco[pais_id]', cep.data.pais_id);
    }
  }, [cep]);

  const onChange = debounce((target) => {
    formikPerson.setFieldValue(target.name, target.value);
  });

  useEffect(() => {
    if (
      formikAddress.values.endereco &&
      formikAddress.values.endereco['uf_id'] > 0
    ) {
      fetchMunicipio(
        null,
        loadOptions &&
          loadOptions.municipio_id
            .replace('{{uf_id}}', formikAddress.values.endereco['uf_id'])
            .replace('http', 'https'),
      );
    }
  }, [formikAddress.values.endereco && formikAddress.values.endereco['uf_id']]);

  const onChangeAddress = (target) => {
    formikAddress.setFieldValue(target.name, target.value);
    if (target.name === 'endereco[uf_id]') {
      fetchMunicipio(
        null,
        loadOptions &&
          loadOptions.municipio_id
            .replace('{{uf_id}}', target.value)
            .replace('http', 'https'),
      );
    }
    if (target.name === 'endereco[cep]' && target.value.length >= 9) {
      //mudar depois que foi mudado no banco
      fetchCep(
        null,
        validateCep
          .replace('{{cep}}', target.value)
          .replace('app/', `api/${selectedLang}/`),
      );
    }
  };

  useEffect(() => {
    setFieldsAddress(
      fieldsAddress.map((row) => {
        if (row.name.includes('municipio')) {
          row.options_elements = municipio;
        }
        return row;
      }),
    );
  }, [municipio]);

  const goFinish = () => {
    goTo(`finish/${routeParams.centro_custo_id}`);
  };

  return (
    <Layout
      content={
        <Container className={classes.container} maxWidth="xl">
          <DiscountViewContainer inPayment={true} paymentView />
          <Grid container className={classes.grid} spacing={4}>
            <Grid item xs={12}>
              <Typography className={classes.textHeader} gutterBottom>
                <Translator transRef={'translation.Confirme os dados para pagamento'} />
              </Typography>
            </Grid>
            <PaymentContainer
              goFinish={goFinish}
              centro_custo_id={routeParams.centro_custo_id}
              toast={toast}
              paymentType={paymentType}
              subscriptionState={subscription}
              paymentRedux={payment}
              total={total}
              user={user}
              lang={selectedLang}
            />
          </Grid>
        </Container>
      }
    />
  );
};

export default PaymentViewContainer;
