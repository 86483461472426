import MODULE_NAME from './constants';

export const SET_SELECTED_COURSE = `${MODULE_NAME}/SET_SELECTED_COURSE`;

export const RESET_SELECTED_COURSE = `${MODULE_NAME}/RESET_SELECTED_COURSE`;

export const UPDATE_SELECTED_COURSE = `${MODULE_NAME}/UPDATE_SELECTED_COURSE`;

export const setSelectCourse = (value) => ({
  type: SET_SELECTED_COURSE,
  payload: { courseSelected: value },
});

export const updateSelectCourse = (params) => ({
  type: UPDATE_SELECTED_COURSE,
  payload: {
    course: params.course,
    element: params.element,
    value: params.value,
  },
});

export const resetSelectCourse = () => ({
  type: RESET_SELECTED_COURSE,
});
