import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Translator } from '@itarget/shared';
import {
  Card,
  CardContent,
  Checkbox,
  Typography,
  Icon,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
} from '@material-ui/core';
import useStyles from './style';
import { useRedirect, formatMoney } from '@itarget/shared';
const CardComponent = (props) => {
  const { width, closeCheckbox } = props;
  const selectedCourse = useSelector((state) => state.course.courseSelected);
  const [openConfirm, setOpenConfirm] = useState(false);
  const routeParams = useParams();
  const { goTo } = useRedirect();
  let opacity = closeCheckbox ? 0.5 : 1;

  const classes = useStyles({ opacity, width });
  const settingDollar = useSelector((state) => state.payment.settingDollar);
  const status =
    props.dataInscricoes && props.dataInscricoes.status_inscricao_descricao;

  const onClickCard = () => {
    if (closeCheckbox) {
      if (selectedCourse.length > 0) {
        handleClickOpen();
        return false;
      }
      goTo(`listing/${routeParams.centro_custo_id}`);
    }
  };

  const handleClickOpen = () => {
    setOpenConfirm(true);
  };

  const handleClose = () => {
    setOpenConfirm(false);
  };

  return (
    <>
      <Dialog
        open={openConfirm}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Translator transRef={'translation.Deseja continuar?'} />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Translator
              transRef={
                'translation.Se você continuar os itens do carrinho vão ser limpados'
              }
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            <Translator transRef={'translation.Cancelar'} />
          </Button>
          <Button
            onClick={() => goTo(`listing/${routeParams.centro_custo_id}`)}
            color="primary"
            autoFocus
          >
            <Translator transRef={'translation.Continuar'} />
          </Button>
        </DialogActions>
      </Dialog>
      <Card className={classes.root} onClick={() => onClickCard()}>
        <CardContent className={classes.header}>
          <Typography variant="h5">
            <Translator transRef={`translation.${props.title}`} />
          </Typography>
          {props.closeCheckbox ? (
            <></>
          ) : (
            //<Icon>lock</Icon>
            <Checkbox
              checked={props.checkboxState.includes(props.name)}
              onChange={(ev) => props.handleChangeCheckbox(ev)}
              name={`${props.name}`}
              className={classes.checkbox}
              color="primary"
            />
          )}
        </CardContent>
        <CardContent className={classes.contentCard}>
          <Typography
            variant="h5"
            gutterBottom
            className={classes.titleContent}
          >
            <Translator transRef={`translation.${props.nameCourse}`} />
          </Typography>
          <div className={classes.footer}>
            {(props.dateInitial || props.dateFinal) && (
              <div className={classes.info}>
                <Icon>date_range</Icon>
                <Translator
                  transRef={`translation.${
                    props.dateInitial && props.dateInitial
                  } - ${props.dateFinal}`}
                />
              </div>
            )}
            {(props.hourInitial || props.hourFinal) && (
              <div className={classes.info}>
                <Icon>query_builder</Icon>
                <Translator
                  transRef={`translation.${props.hourInitial} - ${props.hourFinal}`}
                />
              </div>
            )}
            {props.price !== '0,00' && (
              <div className={classes.info}>
                <Icon>local_atm</Icon>
                R$ {props.price}
                {settingDollar?.filter((sd) => sd.codigo === 683)?.length > 0 &&
                  '/$' +
                    formatMoney.toReal(
                      props.priceReal /
                        settingDollar?.filter((sd) => sd.codigo === 683)[0]
                          .valor_referencia,
                    )}
              </div>
            )}
            {props.dataInscricoes &&
              (props.dataInscricoes.status_inscricao === 1 ? (
                <div className={classes.statusPendente}>
                  <Icon>assignment</Icon>
                  <Translator transRef={`translation.${status}`} />
                </div>
              ) : (
                props.dataInscricoes.status_inscricao === 2 && (
                  <div item className={classes.statusOk}>
                    <Icon>assignment</Icon>
                    <Translator transRef={`translation.${status}`} />
                  </div>
                )
              ))}
          </div>
        </CardContent>
      </Card>
    </>
  );
};
export default CardComponent;
