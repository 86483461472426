import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  btnsContainerLogin: {
    position: 'absolute',
    display: 'flex',
    width: '150px',
    left: '44%',
		top:'30%',
    cursor: 'pointer',
  },
  flagsLogin: {
    width: '30px',
    height: '30px',
    marginLeft: '5px',
    marginRight: '5px',
    transitionDuration: '0.2s',
    '&:hover': {
      width: '40px',
      height: '40px',
    },
  },
  btnsContainerGeneral: {
    position: 'inherit',
    display: 'flex',
    right: '0',
    width: '150px',
    cursor: 'pointer',
    zIndex: '1',
  },
  flagsGeneral: {
    width: '25px',
    height: '25px',
    marginLeft: '5px',
    marginRight: '5px',
    transitionDuration: '0.2s',
    '&:hover': {
      width: '30px',
      height: '30px',
    },
  },
}));

export default useStyles;
