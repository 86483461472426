import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const RouteContainer = ({ isProtected = false, roles = [], ...props }) => {
  const isAutenticated = useSelector((state) => state.auth.isAutenticated);
  const isAutenticating = useSelector((state) => state.auth.isAutenticating);
  
  if (isProtected && isAutenticating) {
    return <div>loading</div>;
  }

  if (isProtected && !isAutenticating && !isAutenticated) {
    let url = window.location.href.split('/')
    return <Redirect to={`/login/${url[url.length - 1]}`} />
  }

  return <Route {...props} />;
};

export default RouteContainer;
