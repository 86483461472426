import produce from 'immer';
import * as actions from './actions';

const initialState = {
  courseSelected: [],
};

export const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case actions.SET_SELECTED_COURSE:
      state.courseSelected = action.payload.courseSelected;
      break;
    case actions.UPDATE_SELECTED_COURSE:
      state.courseSelected = state.courseSelected.map((res) => {
        if (res.aga_id === action.payload.course.aga_id) {
          res[action.payload.element] = action.payload.value;
        }
        return res;
      });
      break;
    case actions.RESET_SELECTED_COURSE:
      state.courseSelected = [];
      break;
  }
}, initialState);
