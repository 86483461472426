import MODULE_NAME from './constants';

export const SET_LANG = `${MODULE_NAME}/SET_LANG`;
export const SET_TRANSLATION = `${MODULE_NAME}/SET_TRANSLATION`;

export const setLang = (lang) => ({
  type: SET_LANG,
  payload: { lang },
});

export const setTranslation = (translation) => ({
  type: SET_TRANSLATION,
  payload: { translation },
});
