import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Typography,
  Hidden,
  Paper,
  CardContent,
  Icon,
  AppBar,
  Tabs,
  Tab,
  withStyles,
  Box,
} from '@material-ui/core';
import {
  Config,
  FormLoginContainer,
  RegisterViewContainer,
  Translator,
} from '@itarget/shared';

import { auth, toast } from '../../../redux';
import AuthorizeAccess from '../../../components/AuthorizeAccess';
import '../../style.scss';
import { useSelector } from 'react-redux';
import BtnLanguagens from '../../../components/BtnLanguagens/BtnLanguagens';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const AntTab = withStyles((theme) => ({
  root: {
    fontSize: 11,
  },
  labelIcon: {
    minHeight: 53,
    '& $wrapper > *:first-child': {
      marginBottom: 0,
    },
  },
  wrapper: {
    flexDirection: 'row',
    letterSpacing: '1px',
    textTransform: 'uppercase',
    '& svg, .material-icons': {
      fontSize: 20,
      marginRight: 8,
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const LoginView = ({
  value,
  props,
  centro_custo_id,
  language,
  textLoginStation,
  setting,
  handlePdpOpen,
  handleChange,
  authorizedAccess,
  checkedAuthorize,
  handleAuthorize,
  onClickAuthorize,
  ModalPdp,
  openPdp,
  handlePdpClose,
  rulesStation,
  valueModalPdp,
  rulesLGPD,
  lang,
}) => {
  let translation = useSelector((state) => state.language.translation);
  return (
    <>
      {!authorizedAccess && (
        <AuthorizeAccess
          checked={checkedAuthorize}
          handleChange={handleAuthorize}
          onClick={onClickAuthorize}
        />
      )}
      <ModalPdp
        open={openPdp}
        handleOpen={handlePdpOpen}
        handleClose={handlePdpClose}
        routeParams={{ centro_custo_id }}
        rulesStation={rulesStation}
        valueModalPdp={valueModalPdp}
        rulesLGPD={rulesLGPD}
      />
      <Grid className={'header'}>
        <div className={'content'}>
          <object
            type="image/svg+xml"
            data={
              props?.pathImage
                ? props?.pathImage
                : `/assets/images/clients/${Config.client}/${centro_custo_id}/${language ? language : 'pt-br'
                }/logos/logo.svg`
            }
            width="120"
            height="80"
          />
        </div>
        <div className={'content'}>
          <img
            src={
              props?.pathImage
                ? props?.pathImage
                : `/assets/images/clients/${Config.client}/${centro_custo_id}/${language ? language : 'pt-br'
                }/logos/logo-administrador.png`
            }
            height="50"
          />
        </div>
        <BtnLanguagens centroCusto={centro_custo_id} page="login" />
      </Grid>
      <Grid container spacing={2} className={'rootLogin'}>
        {!centro_custo_id && (
          <div>
            <Dialog aria-labelledby="simple-dialog-title" open={true}>
              <DialogTitle id="simple-dialog-title">
                <Translator transRef={'translation.Evento nao encontrado!'} />
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <Translator transRef={'translation.Verifique se a url fornecida esta completa.'} />
                </DialogContentText>
              </DialogContent>
            </Dialog>
          </div>
        )}
        <Grid item xs sm={12}>
          <Grid item>
            <Typography
              variant="h2"
              component="h2"
              color="textSecondary"
              align="center"
              gutterBottom
              className={'textHead'}
            >
              <Translator transRef={'translation.Realize o login para prosseguir'} />
            </Typography>
          </Grid>
        </Grid>
        <Hidden smDown>
          <Grid item className={'boxLogin'}>
            <Paper>
              <CardContent>
                <Grid className={'formContainer'}>
                  <Typography
                    style={{
                      textAlign: 'center',
                      color: '#9c9c9c',
                      fontSize: 15,
                      margin: 30,
                    }}
                  >
                    {textLoginStation}
                  </Typography>
                  <Icon style={{ color: 'darkgreen' }}>how_to_reg</Icon>
                  <Typography
                    variant="caption"
                    gutterBottom
                    style={{ color: 'darkgreen' }}
                  >
                    <Translator transRef={'translation.TENHO LOGIN'} />
                  </Typography>
                  <FormLoginContainer
                    redirect={`recovery/${centro_custo_id}`}
                    auth={auth}
                    centro_custo_id={centro_custo_id}
                    setting={setting}
                    handlePdpOpen={handlePdpOpen}
                  />
                </Grid>
              </CardContent>
            </Paper>
          </Grid>
          <Grid item className={'boxLogin'}>
            <Paper>
              <CardContent>
                <Grid className={'formContainer'}>
                  <Icon style={{ color: 'darkgoldenrod' }}>person_add</Icon>
                  <Typography
                    variant="caption"
                    gutterBottom
                    style={{ color: 'darkgoldenrod' }}
                  >
                    <Translator transRef={'translation.NOVO CADASTRO'} />
                  </Typography>
                  <RegisterViewContainer
                    tipo_interface_id={109}
                    centro_custo_id={centro_custo_id}
                    auth={auth}
                    handlePdpOpen={handlePdpOpen}
                    lang={lang}
                  />
                </Grid>
              </CardContent>
            </Paper>
          </Grid>
          <Grid className={'footer'}>
            <Translator transRef={'translation.© 2021 - Itarget Tecnologia - Todos os direitos reservados'} />
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <div className={'tabsMobile'}>
            <AppBar position="static" color="default">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <AntTab
                  className={'tabItem'}
                  icon={<Icon style={{ color: 'darkgreen' }}>how_to_reg</Icon>}
                  label="Tenho Login"
                  {...a11yProps(0)}
                />
                <AntTab
                  className={'tabItem'}
                  icon={
                    <Icon style={{ color: 'darkgoldenrod' }}>person_add</Icon>
                  }
                  label="Novo Cadastro"
                  {...a11yProps(1)}
                />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
              <FormLoginContainer
                redirect={`recovery/${centro_custo_id}`}
                auth={auth}
                centro_custo_id={centro_custo_id}
                setting={setting}
                handlePdpOpen={handlePdpOpen}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <RegisterViewContainer
                tipo_interface_id={109}
                centro_custo_id={centro_custo_id}
                auth={auth}
                handlePdpOpen={handlePdpOpen}
                lang={lang}
              />
            </TabPanel>
          </div>
        </Hidden>
      </Grid>
    </>
  );
};

export default LoginView;
