import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  course,
  auth,
  toast,
  payment,
  setting as settingRedux,
} from '../../../redux';
import CardComponent from '../../../components/CardCourse';
import Layout from '../../../components/Layout';
import DiscountViewContainer from './DiscountViewContainer';
import {
  formatDate,
  formatMoney,
  useFetchApi,
  Loading,
  Config,
  convertBase64,
  Translator,
  ModalCategoryReceipt,
} from '@itarget/shared';

import useStyles from './style';
import {
  TextField,
  Grid,
  MenuItem,
  Typography,
  FormControlLabel,
  Container,
  Collapse,
  Button,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const CourseViewContainer = (props) => {
  const dispatch = useDispatch();
  const routeParams = useParams();
  const classes = useStyles({ routeParams });
  const [listCourse, setListCourse] = useState();
  const [listAddress, setListAddress] = useState(true);
  const [listCourseSelected, setListCourseSelected] = useState([]);
  const [listIdAgAtv, setListIdAgAtv] = useState([]);
  const [checkboxState, setCheckboxState] = useState({});
  const [categorySelected, setCategorySelected] = useState(false);
  const [listParceiros, setListParceiros] = useState([]);
  const [dataUpload, setDataUpload] = useState({});
  const [dataPersonCenterCostApi, setDataPersonCenterCostApi] = useState({});
  const [statusUpload, setStatusUpload] = useState('');
  const [currentRow, setCurrentRow] = useState({});
  const [selectedEntidade, setSelectedEntidade] = useState();
  const [disabledListPartners, setDisabledListPartners] = useState(false);
  const [registeredCourse, setRegisteredCourse] = useState();
  const [openModalReceipt, setOpenModalReceipt] = useState(false);
  const [textWelcome, setTextWelcome] = useState('');

  const user = useSelector((state) => state.auth.user);
  const selectedCourse = useSelector((state) => state.course.courseSelected);
  const statusReceipt = useSelector((state) => state.payment.statusReceipt);
  const selectedLang = useSelector((state) => state.language.lang);

  const configIdLanguage =
    selectedLang === 'es' ? 3 : selectedLang === 'en' ? 2 : 1;

  const { data: setting, fetchData: fetchSetting } = useFetchApi(
    Config.baseURL +
      `api/${selectedLang}/setting?centro_custo_id=${routeParams.centro_custo_id}&codigo[]=359&codigo[]=9&codigo[]=13`,
  );

  const {
    data: courses,
    isLoading: isLoadingListCourses,
    fetchData: fetchListCourses,
  } = useFetchApi(
    Config.baseURL +
      `api/${selectedLang}/finance/activity-available?ordenacao=categoria_tatv_id,desc_aga&pessoa_id=${user.data.id}&centro_custo_id=${routeParams.centro_custo_id}&per_page=9999`,
  );

  const {
    data: parceiros,
    isLoading: isLoadingParceiros,
    error: erroParceiros,
    fetchData: fetchParceiros,
  } = useFetchApi(
    Config.baseURL +
      `api/${selectedLang}/entity/${routeParams.centro_custo_id}/partner?associacao_id=${user.data.associacao_id}&pessoa_id=${user.data.id}&centro_custo_id=${routeParams.centro_custo_id}`,
  );

  const {
    data: address,
    isLoading: isLoadingListAddress,
    error: erroListAddress,
    fetchData: fetchListAddress,
  } = useFetchApi(
    Config.baseURL + `api/${selectedLang}/address?pessoa_id=${user.data.id}`,
  );

  const {
    data: personCenterCost,
    isLoading: isLoadingPersonCenterCost,
    error: erroPersonCenterCost,
    fetchData: fetchPersonCenterCost,
  } = useFetchApi(
    Config.baseURL +
      `api/${selectedLang}/center-cost-person?pessoa_id=${user.data.id}&centro_custo_id=${routeParams.centro_custo_id}`,
    'POST',
  );

  useEffect(() => {
    if (personCenterCost) {
      setDataPersonCenterCostApi(personCenterCost.data);
      fetchListCourses();
      fetchParceiros();
    }
  }, [personCenterCost, selectedLang]);

  const {
    data: personCenterCostPut,
    isLoading: isLoadingPersonCenterCostPut,
    error: erroPersonCenterCostPut,
    fetchData: fetchPersonCenterCostPut,
  } = useFetchApi(
    Config.baseURL +
      `api/${selectedLang}/center-cost-person/${
        dataPersonCenterCostApi && dataPersonCenterCostApi.id
      }`,
    'PUT',
  );

  useEffect(() => {
    if (personCenterCostPut) {
      fetchListCourses();
      dispatch(
        toast.actions.enqueue({
          content: personCenterCostPut.message,
          severity: 'success',
        }),
      );
    }
    if (erroPersonCenterCostPut !== null) {
      dispatch(
        toast.actions.enqueue({
          content: erroPersonCenterCostPut,
          severity: 'error',
        }),
      );
    }
  }, [personCenterCostPut, erroPersonCenterCostPut]);

  const {
    data: dataPersonCenterCost,
    isLoading: isLoadingDataPersonCenterCost,
    error: erroDataPersonCenterCost,
    fetchData: fetchDataPersonCenterCost,
  } = useFetchApi(
    Config.baseURL +
      `api/${selectedLang}/center-cost-person?pessoa_id=${user.data.id}&centro_custo_id=${routeParams.centro_custo_id}`,
  );

  const {
    data: upload,
    isLoading: isLoadingUpload,
    error: erroUpload,
    fetchData: fetchUpload,
  } = useFetchApi(Config.baseURL + `api/${selectedLang}/utility/s3`, 'POST');

  const {
    data: saveUpload,
    isLoading: isLoadingSaveUpload,
    error: erroSaveUpload,
    fetchData: fetchSaveUpload,
  } = useFetchApi(Config.baseURL + `api/${selectedLang}/attachments`, 'POST');

  const { data: category, fetchData: fetchListCategory } = useFetchApi(
    Config.baseURL +
      `api/${selectedLang}/center-cost/${routeParams.centro_custo_id}/category?pessoa_id=${user.data.id}&visivel_mod_congresso=S`,
  );

  const {
    data: dataInscricoes,
    isLoading: isLoadingInscricoes,
    error: erroInscricoes,
    fetchData: fetchInscricoes,
  } = useFetchApi(
    Config.baseURL +
      `api/${selectedLang}/subscription?pessoa_id=${user.data.id}`,
  );

  const {
    data: dataConflitAtv,
    isLoading: isLoadingConflitAtv,
    error: erroConflitAtv,
    fetchData: fetchConflitAtv,
  } = useFetchApi(
    Config.baseURL +
      `api/${selectedLang}/activity-schedule/${listIdAgAtv?.toString()}/valid`,
  );

  const { data: idReceipt, fetchData: fetchIdReceipt } = useFetchApi(
    Config.baseURL +
      `api/${selectedLang}/attachments?table=pessoas_centros_custos&tabela_id=${dataPersonCenterCostApi?.id}&tipo_documento_id[]=200`,
  );

  useEffect(() => {
    if (parceiros?.data) {
      setListParceiros(parceiros.data);
    }
    if (parceiros?.data?.status === 'E') {
      setDisabledListPartners(true);
      setSelectedEntidade(parceiros.data.entidade_id);
      setStatusUpload('liberado');
    }
  }, [parceiros]);

  useEffect(() => {
    if (dataPersonCenterCost) {
      setDataPersonCenterCostApi(dataPersonCenterCost.data[0]);
    }
  }, [dataPersonCenterCost]);

  useEffect(() => {
    if (courses?.length > 0) {
      var course_ = courses.filter((row) => {
        if (
          dataInscricoes?.data?.filter(
            (res) =>
              res.agenda_atividade_id === row.aga_id &&
              res.status_inscricao === 1,
          ).length > 0
        ) {
          Object.defineProperty(row, 'valor', {
            enumerable: true,
            configurable: false,
            writable: false,
            value: dataInscricoes?.data?.filter(
              (res) =>
                res.agenda_atividade_id === row.aga_id &&
                res.status_inscricao === 1,
            )?.[0]?.cr_valor,
          });
          return row;
        }
      });
      if (dataInscricoes?.data) {
        dispatch(
          payment.actions.setAllSubscription(
            dataInscricoes.data.filter((r) =>
              course_.map((r) => r.aga_id).includes(r.agenda_atividade_id),
            ),
          ),
        );
      }
      setRegisteredCourse(course_);
      setListCourseSelected(course_);
    }
  }, [courses, dataInscricoes]);

  useEffect(() => {
    if (courses && courses.length > 0) {
      setListCourse(courses);
      fetchInscricoes();
    }
    if (address && address.length > 0) {
      setListAddress(true);
    } else {
      setListAddress(false);
    }
  }, [courses, address, selectedLang]);

  useEffect(() => {
    dispatch(payment.actions.setVoucher({}));
    dispatch(payment.actions.setCodVoucher(''));
    fetchListCourses();
    fetchListAddress();
    fetchDataPersonCenterCost();
    fetchParceiros();
    fetchSetting();
  }, []);

  useEffect(() => {
    fetchListCourses();
  }, [selectedLang]);

  useEffect(() => {
    if (setting?.data) {
      dispatch(settingRedux.actions.setSettings(setting.data));
    }
    if (setting?.data?.find((r) => r.codigo === 359)?.valor_referencia) {
      setTextWelcome(
        setting?.data?.find(
          (r) => r.codigo === 359 && r.idioma_id === configIdLanguage,
        )?.valor_referencia,
      );
    }
  }, [setting, selectedLang]);

  useEffect(() => {
    if (!user.data.categoria_centro_custo_descricao) {
      fetchListCategory();
    } else {
      setCategorySelected(true);
    }
  }, [user.data]);

  useEffect(() => {
    setCheckboxState(
      selectedCourse &&
        selectedCourse.map((r) => {
          return r.atv_id;
        }),
    );
    if (listCourseSelected > selectedCourse) {
      setListCourseSelected(selectedCourse);
    }
  }, [selectedCourse]);

  useEffect(() => {
    dispatch(course.actions.setSelectCourse(listCourseSelected));
  }, [listCourseSelected]);

  useEffect(() => {
    if (listIdAgAtv && listIdAgAtv.length > 0) {
      fetchConflitAtv();
    }
  }, [listIdAgAtv]);

  const handleChangeCheckbox = (row, event) => {
    if (
      selectedCourse.filter(
        (res) =>
          res.aga_id !== row.aga_id &&
          res.data_inicio_aga === row.data_inicio_aga &&
          res.hora_inicio_aga === row.hora_inicio_aga,
      ).length > 0
    ) {
      dispatch(
        toast.actions.enqueue({
          content:
            'O curso selecionado tem choque de horario com outra atividade selecionada',
          severity: 'error',
        }),
      );
      return false;
    }
    setCurrentRow(row);
    if (event.target.checked) {
      setListCourseSelected((listCourseSelected) => [
        ...listCourseSelected,
        row,
      ]);

      let agAtvId = dataInscricoes?.data?.map((a) => {
        return a.agenda_atividade_id;
      });
      agAtvId && agAtvId.push(row.aga_id);
      setListIdAgAtv(agAtvId);
    } else {
      setListCourseSelected(listCourseSelected.filter((res) => res !== row));
    }
  };

  useEffect(() => {
    if (dataConflitAtv?.[0]?.agenda_atividade_id_out?.length > 0) {
      dispatch(
        toast.actions.enqueue({
          content:
            'O curso selecionado tem choque de horario com outra atividade selecionada',
          severity: 'error',
        }),
      );
      setListCourseSelected(
        listCourseSelected.filter((res) => res !== currentRow),
      );
    }
  }, [dataConflitAtv]);

  useEffect(() => {
    if (!categorySelected && category?.data?.length === 1) {
      fetchPersonCenterCost(
        JSON.stringify({
          pessoa_id: user.data.id,
          categoria_centro_custo_id: category.data[0].id,
          categoria_centro_custo_princ: 'S',
          nome_cracha: user.data.nome,
        }),
      );
      if (category.data[0].id) {
        let data = user.data;
        dispatch(
          auth.actions.updateUser({
            ...user,
            data: {
              ...data,
              categoria_centro_custo_descricao: category.data.filter(
                (res) => res.id === category.data[0].id,
              )[0].descricao,
            },
          }),
        );
        setCategorySelected(true);
      }
    }
  }, [category]);

  const onChangeCategory = async (e) => {
    await fetchPersonCenterCost(
      JSON.stringify({
        pessoa_id: user.data.id,
        categoria_centro_custo_id: e.value,
        categoria_centro_custo_princ: 'S',
        nome_cracha: user.data.nome,
      }),
    );
    if (e.value) {
      let data = user.data;
      dispatch(
        auth.actions.updateUser({
          ...user,
          data: {
            ...data,
            categoria_centro_custo_descricao: category.data.filter(
              (res) => res.id === e.value,
            )[0].descricao,
            categoria_centro_custo_id: category.data.filter(
              (res) => res.id === e.value,
            )[0].id,
          },
        }),
      );
      setCategorySelected(true);
      setOpenModalReceipt(true);
    }
  };

  useEffect(() => {
    if (dataPersonCenterCostApi && dataPersonCenterCostApi.id) {
      fetchIdReceipt();
    }
  }, [dataPersonCenterCostApi]);

  const onChangeSelectEntidade = (e) => {
    if (e.target.value) {
      setSelectedEntidade(e.target.value);
      setStatusUpload('liberado');
    }
  };

  const onChangeUpload = async (e) => {
    setStatusUpload('carregando');

    const file = e.target.files[0];
    const base64 = await convertBase64(file);

    await fetchUpload(
      JSON.stringify({
        file: base64,
      }),
    );

    await fetchPersonCenterCostPut(
      JSON.stringify({
        id: dataPersonCenterCostApi.id,
        entidade_id_parceira: selectedEntidade,
      }),
    );

    fetchParceiros();
  };

  useEffect(() => {
    if (statusReceipt && statusReceipt.id) {
      setOpenModalReceipt(false);
    }
  }, [statusReceipt]);

  useEffect(() => {
    setDataUpload(upload);
  }, [upload]);

  useEffect(() => {
    if (dataUpload && dataUpload.message) {
      setStatusUpload(dataUpload.message);
    }
  }, [dataUpload]);

  useEffect(() => {
    if (dataUpload && dataUpload.message) {
      let extension = dataUpload.data.url.split('.');
      fetchSaveUpload(
        JSON.stringify({
          tabela: 'pessoas_centros_custos',
          tabela_id: dataPersonCenterCostApi.id,
          pessoa_centro_custo_id: dataPersonCenterCostApi.id,
          nome_original: dataUpload.data.file,
          nome_armazenado: dataUpload.data.url,
          mime_type: extension[extension.length - 1],
          tipo_documento_id: 201,
        }),
      );
      setStatusUpload(dataUpload.message);
      setListParceiros([]);
    }
  }, [dataUpload]);

  const refreshListCourses = () => {
    fetchListCourses();
  };
  let entidade = Translator({ transRef: 'translation.Entidade' }).props
    .children;
  let categoria = Translator({ transRef: 'translation.Categoria' }).props
    .children;
  return (
    <Layout
      content={
        <Container className={classes.container} maxWidth="xl">
          {dataPersonCenterCostApi &&
            dataPersonCenterCostApi.id &&
            idReceipt &&
            idReceipt.data.length === 0 && (
              <ModalCategoryReceipt
                open={openModalReceipt}
                setOpen={setOpenModalReceipt}
                toast={toast}
                payment={payment}
                centerCostId={routeParams.centro_custo_id}
              />
            )}
          <div className={classes.root}>
            <DiscountViewContainer
              courseView
              registeredCourse={registeredCourse}
              dataInscricoes={dataInscricoes}
              refreshListCourses={refreshListCourses}
            />
            <Grid container spacing={4} className={classes.grid}>
              {isLoadingParceiros ? (
                <></>
              ) : listParceiros && listParceiros.status && categorySelected ? (
                <Grid container spacing={3} className={classes.comprovante}>
                  {listParceiros.status !== 'E' && (
                    <Grid item sm={12} xs={12}>
                      <Typography className={classes.textHeader} gutterBottom>
                        <Translator
                          transRef={`translation.Caso seja associado de alguma filiada estadual, anexe a carta de adimplência ou o comprovante de pagamento da anuidade.`}
                        />
                      </Typography>
                    </Grid>
                  )}
                  {user?.data?.associado !== true &&
                  <Grid item sm={6} xs={6}>
                    {/*  */}
                    <TextField
                      className={classes.field}
                      select
                      label={entidade}
                      name="entidade_parceira"
                      required={true}
                      onChange={(ent) => onChangeSelectEntidade(ent)}
                      disabled={disabledListPartners}
                      defaultValue={selectedEntidade}
                    >
                      <MenuItem value={''}>
                        <Translator transRef={'translation.Selecione'} />
                      </MenuItem>
                      {listParceiros &&
                        Object.values(listParceiros)
                          .filter((r) => r && r.id)
                          .map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              <Translator
                                transRef={`translation.${option.nome}`}
                              />
                            </MenuItem>
                          ))}
                    </TextField>
                    {listParceiros.anexo && (
                      <>
                        <Alert
                          onClick={() =>
                            window.open(listParceiros.anexo, '_blank')
                          }
                          color="info"
                          className={classes.entityAttachment}
                        >
                          <Translator
                            transRef={'translation.Download do comprovante'}
                          />
                        </Alert>
                      </>
                    )}
                  </Grid>
                  }
                  <Grid item sm={6} xs={6}>
                    {statusUpload === 'liberado' ? (
                      <>
                        <label htmlFor="btn-upload">
                          <input
                            id="btn-upload"
                            name="btn-upload"
                            style={{ display: 'none' }}
                            type="file"
                            onChange={(e) => onChangeUpload(e)}
                          />
                          <Button
                            className={classes.btnUpload}
                            variant="outlined"
                            component="span"
                          >
                            <Translator
                              transRef={`translation.${
                                disabledListPartners
                                  ? 'ALTERAR O COMPROVANTE'
                                  : 'SELECIONE O COMPROVANTE'
                              }`}
                            />
                          </Button>
                        </label>
                      </>
                    ) : statusUpload === 'carregando' ? (
                      <Loading text="Carregando..." />
                    ) : (
                      <Typography
                        className={classes.textHeader}
                        gutterBottom
                        className={classes.msnUpload}
                      >
                        <Translator transRef={`translation.${statusUpload}`} />
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              ) : (
                <></>
              )}
              {isLoadingListAddress || listAddress ? (
                <></>
              ) : (
                <Grid sm={12} xs={12}>
                  <Collapse in={!listAddress}>
                    <Alert
                      severity="error"
                      onClose={() => {
                        setListAddress(true);
                      }}
                    >
                      <Translator
                        transRef={`translation.Lembre de completar seus dados na tela de pagamentos para concluir suas inscrições.`}
                      />
                    </Alert>
                  </Collapse>
                </Grid>
              )}
              <Grid container spacing={2} xs={12}>
                <Grid item xs>
                  <Typography className={classes.textHeader} gutterBottom>
                    <Translator
                      transRef={`translation.
                      ${
                        categorySelected
                          ? courses?.length > 0
                            ? textWelcome
                            : 'Nenhuma atividade disponível!'
                          : 'Selecione sua categoria para prosseguir'
                      }
                    `}
                    />
                  </Typography>
                </Grid>
                {!categorySelected && (
                  <Grid item xs={2}>
                    <TextField
                      className={classes.field}
                      select
                      label={categoria}
                      name="categoria_centro_custo_id"
                      required={true}
                      onChange={({ target }) => onChangeCategory(target)}
                    >
                      <MenuItem value={''}>
                        <Translator transRef={`translation.Selecione`} />
                      </MenuItem>
                      {category &&
                        category.data &&
                        category.data.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            <Translator
                              transRef={`translation.${option.descricao}`}
                            />
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>
                )}
              </Grid>
              <Grid container spacing={3}>
                {isLoadingPersonCenterCostPut || isLoadingListCourses ? (
                  <Loading text="Carregando..." />
                ) : (
                  listCourse &&
                  listCourse.map((row, i) => (
                    <Grid item sm={6} md={4} lg={4} xs={12} key={i}>
                      <FormControlLabel
                        className={classes.formCards}
                        control={
                          <CardComponent
                            row={row}
                            width={300}
                            title={row.desc_tatv}
                            nameCourse={row.desc_aga}
                            dateInitial={formatDate.ptBr(row.data_inicio_aga)}
                            dateFinal={formatDate.ptBr(row.data_fim_aga)}
                            hourInitial={formatDate.hour(row.hora_inicio_aga)}
                            hourFinal={formatDate.hour(row.hora_fim_aga)}
                            price={formatMoney.toReal(row.valor)}
                            priceReal={row.valor}
                            handleChangeCheckbox={(ev) =>
                              handleChangeCheckbox(row, ev)
                            }
                            closeCheckbox={
                              dataInscricoes?.data?.filter(
                                (res) => res.agenda_atividade_id === row.aga_id,
                              ).length > 0
                            }
                            dataInscricoes={
                              dataInscricoes?.data?.filter(
                                (res) => res.agenda_atividade_id === row.aga_id,
                              )[0]
                            }
                            checkboxState={checkboxState}
                            name={row.atv_id}
                          />
                        }
                      />
                    </Grid>
                  ))
                )}
              </Grid>
            </Grid>
          </div>
        </Container>
      }
    />
  );
};

export default CourseViewContainer;
