import React from 'react';
import { useSelector } from 'react-redux';
import {
  Card,
  CardContent,
  Checkbox,
  Typography,
  Icon,
  Link,
  Grid,
  Button,
} from '@material-ui/core';
import { formatMoney, Translator } from '@itarget/shared';
import useStyles from './style';

const CardStatusComponent = (props) => {
  const classes = useStyles();
  const settingDollar = useSelector((state) => state.payment.settingDollar);

  let border =
    props.status_code === 1 || props.status_code === 2
      ? '1px solid #ff0000'
      : '1px solid #4fc827';
  return (
    <>
      <Card className={classes.root} style={{ border: border }}>
        <CardContent className={classes.header}>
          <Typography variant="h5" style={{ fontWeight: 'bold' }}>
            <Translator transRef={`translation.${props.nameTypeActivity}`} />
          </Typography>

          {props.row.status_inscricao !== null &&
            props.row.status_inscricao === 1 && (
              <Grid>
                <Checkbox
                  checked={props.checkboxState.includes(props.name)}
                  onChange={(ev) => props.handleChangeCheckbox(ev)}
                  name={`${props.name}`}
                  className={classes.checkbox}
                  color="primary"
                />
                <Button onClick={props.onClickCancel}>
                  <Icon>close</Icon>
                </Button>
              </Grid>
            )}
        </CardContent>
        <CardContent className={classes.contentCard}>
          <Typography
            variant="h5"
            gutterBottom
            className={classes.titleContent}
          >
            <Translator transRef={`translation.${props.nameCourse}`} />
          </Typography>
          <Grid container className={classes.infos}>
            {props.vencimento && (
              <Grid item className={classes.info}>
                <Icon>date_range</Icon>
                <Translator transRef={`translation.${props.vencimento}`} />
              </Grid>
            )}
            {props.price && (
              <Grid item className={classes.info}>
                <Icon>local_atm</Icon>
                {}
                R$ {}
                {props.price > 0
                  ? formatMoney.toReal(props.price) +
                    (settingDollar?.filter((sd) => sd.codigo === 683)?.length >
                      0 &&
                      '/$' +
                        formatMoney.toReal(
                          props.price /
                            settingDollar?.filter((sd) => sd.codigo === 683)[0]
                              .valor_referencia,
                        ))
                  : '0,00'}
              </Grid>
            )}

            {props.recibo && (
              <Grid item className={classes.info}>
                <Icon>receipt</Icon>
                <Link
                  to={props.recibo}
                  target="_blank"
                  onClick={(event) => {
                    event.preventDefault();
                    window.open(props.recibo);
                  }}
                >
                  <Translator transRef={'translation.Comprovante'} />
                </Link>
              </Grid>
            )}
            {props.row.status_inscricao === 1 ? (
              <Grid item className={classes.statusPendente}>
                <Icon>assignment</Icon>
                <Translator
                  transRef={`translation.${props.row.status_inscricao_descricao}`}
                />
              </Grid>
            ) : (
              props.row.status_inscricao === 2 && (
                <Grid item className={classes.statusOk}>
                  <Icon>assignment</Icon>
                  <Translator
                    transRef={`translation.${props.row.status_inscricao_descricao}`}
                  />
                </Grid>
              )
            )}
            {props.row.status_inscricao !== 2 && props.row.hash_transacao && (
              <Grid item className={classes.info}>
                <Icon>receipt</Icon>
                <Link
                  to={props.recibo}
                  target="_blank"
                  onClick={(event) => {
                    event.preventDefault();
                    props.reprintBillet();
                  }}
                >
                  <Translator transRef={'translation.Reimprimir boleto'} />
                </Link>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default CardStatusComponent;
