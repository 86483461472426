import React from 'react';
import Toaster from '../components/Toaster';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from '../redux';

const ToasterContainer = () => {
  const dispatch = useDispatch();
  const toasts = useSelector((state) => state.toast.toasts);

  const onClose = (toastId) => {
    dispatch(toast.actions.unqueue(toastId));
  };

  return <Toaster toasts={toasts} onClose={onClose} />;
};

export default ToasterContainer;
