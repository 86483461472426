import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    borderRadius: 20,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      '& .MuiGrid-item': {
        display: 'flex',
        justifyContent: 'center',
      },
    },
  },
  header: {
    color: '#2797C8',
    fontWeight: 800,
    borderBottom: '1px solid #ECECEC',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5px 20px',
    marginTop: 10,
  },
  checkbox: {},
  contentCard: {
    height: '100%',
    flexWrap: 'wrap',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  infos: {
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  info: {
    border: '2px solid #1C608A',
    borderRadius: '20px',
    display: 'flex',
    alignItems: 'center',
    color: '#1C608A',
    fontSize: 12,
    padding: '0 15px',
    margin: '0 5px',
    height: '30px',
  },
  statusPendente: {
    background: '#ff0000',
    border: '2px solid #ff0000',
    borderRadius: '20px',
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    fontSize: 12,
    padding: '0 15px',
    margin: '0 5px',
    height: '30px',
  },
  statusOk: {
    border: '2px solid #4fc827',
    borderRadius: '20px',
    display: 'flex',
    alignItems: 'center',
    color: '#4fc827',
    fontSize: 12,
    padding: '0 15px',
    margin: '0 5px',
    height: '30px',
  },
}));

export default useStyles;
