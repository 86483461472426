import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  header: {
    background: '#FFFFFF',
    position: 'fixed',
    top: 0,
    width: '100%',
    height: 70,
    boxShadow: '0 0 1em #c8c8c8',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 999,
    left: 0,
  },
  footer: {
    background: '#1E2125',
    position: 'fixed',
    overflow: 'hidden',
    bottom: 0,
    width: '100%',
    height: 35,
    display: 'flex',
    alignItems: 'center',
    padding: '0 30px',
    zIndex: 999,
    left: 0,
    fontSize: '12px',
    color: '#FFF',
  },
  content: {
    margin: '0 30px',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      margin: '0 10px',
      '& .MuiButtonBase-root': {
        paddingRight: 0,
      },
    },
  },
  buttonIcon: {
    cursor: 'pointer',
  },
  imgUser: {
    borderRadius: ' 100%',
    width: 40,
  },
  userName: {
    '& .MuiTypography-root': {
      fontSize: 14,
      marginRight: 8,
      fontWeight: 'bold',
      color: '#4e4e4e',
    },
  },
  userCategoria: {
    '& .MuiTypography-root': {
      fontSize: 12,
      marginRight: 8,
      fontWeight: '100',
      color: '#4e4e4e',
    },
  },
  gridUser: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  itensMenu: {
    '@media print': {
      display: 'none',
    },
  },
  logoHeader: {
    maxHeight: '60px',
    maxWidth: 760,
    width: '40vw',
    ['@media (max-width:1478px)']: {
      width: '30vw',
    },
    ['@media (max-width:1279px)']: {
      width: '40vw',
    },
    ['@media (max-width:959px)']: {
      width: '60vw',
    },
    ['@media (max-width:806px)']: {
      width: '25vw',
      height: '10vh',
    },
    ['@media (max-width:390px)']: {
      width: '15vw',
      height: 'auto',
    },
  },
  popper: {
    zIndex: 999,
    boxShadow: '0 0 1em black',
  },
}));

export default useStyles;
