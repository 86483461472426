import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    zIndex: 9999,
    position: 'fixed',
    right: 20,
    top: 20,
    background: 'transparent',
  },
}));

export default useStyles;
