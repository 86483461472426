import {
  // take,
  all,
  put,
  delay,
  // fork,
  // cancel,
  call,
  takeLatest,
} from 'redux-saga/effects';
import { Config } from '@itarget/shared';

import * as actions from './actions';
import { actions as authActions } from '../auth';
import { actions as fetchActions } from '../fetch';
import { actions as paymentActions } from '../payment';
import { actions as courseActions } from '../course';
import { actions as toastActions } from '../toast';
import { select } from 'redux-saga/effects';
//AUTHENTICATE
function* setToken(action) {
  const state = yield select();
  yield put(fetchActions.setToken(action.payload.token));
  yield delay(14400000);

  yield put({
    type: 'FETCH_STARTED',
    meta: {
      actions: authActions.AUTHENTICATE_REQUEST,
      body: JSON.stringify({
        login: 'icongressolive',
        senha: 'FRB:y}|Y',
      }),
      url: Config.baseURL + 'api/' + state.language.lang + '/authenticate',
      method: 'POST',
    },
  });
}
//LOGIN
function* loginStarted() {}
function* loginFulfilled(action) {
  yield put(
    toastActions.enqueue({
      content: action.payload.message,
      severity: 'success',
    }),
  );
}
function* loginFailed(action) {
  yield put(
    toastActions.enqueue({
      content: action.payload.message,
      severity: 'error',
    }),
  );
}

function* logout() {
  yield put(paymentActions.resetInitialstate());
  yield put(courseActions.resetSelectCourse());
}

//REGISTER
function* registerStarted() {}

function* registerFulfilled(action) {
  const body = JSON.parse(action.meta.body);
  const state = yield select();
  yield put(
    fetchActions.fetchStarted({
      type: 'FETCH_STARTED',
      meta: {
        actions: actions.LOGIN,
        body: JSON.stringify({
          login: body.pessoa.email,
          senha: body.pessoa.senha,
          centro_custo_id: body.centro_custo_id,
          autorizou_acesso: [body.politica_privacidade],
        }),
        url: Config.baseURL + 'api/' + state.language.lang + '/login',
        method: 'POST',
      },
    }),
  );
  yield put(
    toastActions.enqueue({
      content: action.payload.message,
      severity: 'success',
    }),
  );
}
function* registerFailed(action) {
  yield put(
    toastActions.enqueue({
      content: action.payload.message,
      severity: 'error',
    }),
  );
}

//AUTHENTICATE
function* watchForAuthenticateRequestFulfilled() {
  yield takeLatest(actions.AUTHENTICATE_REQUEST.FULFILLED, setToken);
}

//LOGIN
function* watchForLoginStarted() {
  yield takeLatest(actions.LOGIN.STARTED, loginStarted);
}
function* watchForLoginFulfilled() {
  yield takeLatest(actions.LOGIN.FULFILLED, loginFulfilled);
}
function* watchForLoginFailed() {
  yield takeLatest(actions.LOGIN.FAILED, loginFailed);
}

function* watchForLogout() {
  yield takeLatest(actions.LOGOUT, logout);
}

//REGISTER
function* watchForRegisterStarted() {
  yield takeLatest(actions.REGISTER.STARTED, registerStarted);
}
function* watchForRegisterFulfilled() {
  yield takeLatest(actions.REGISTER.FULFILLED, registerFulfilled);
}
function* watchForRegisterFailed() {
  yield takeLatest(actions.REGISTER.FAILED, registerFailed);
}

function* rootSaga() {
  yield all([
    watchForAuthenticateRequestFulfilled(),
    watchForLoginStarted(),
    watchForLoginFulfilled(),
    watchForLoginFailed(),
    watchForRegisterStarted(),
    watchForRegisterFulfilled(),
    watchForRegisterFailed(),
    watchForLogout(),
  ]);
}

export default rootSaga;
