import React from 'react';
import { Translator } from '@itarget/shared';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';

const ModalPdp = (props) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Translator transRef={`translation.${props.valueModalPdp === 1 ? 'Políticas de privacidade' : 'Regras de inscrição'}`}
        />
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.valueModalPdp === 1 && <div dangerouslySetInnerHTML={{ __html:props.rulesLGPD }} />}
          {props.valueModalPdp === 2 && (
            <div dangerouslySetInnerHTML={{ __html: props.rulesStation }} />
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          <Translator transRef={'translation.FECHAR'} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalPdp;
