import React from 'react';
import { formatMoney, Translator } from '@itarget/shared';
import {
  AccordionDetails,
  AccordionSummary,
  Accordion,
  Typography,
  Icon,
  TextField,
  Grid,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useStyles from './style';
import { useDispatch, useSelector } from 'react-redux';

const CardComponent = (props) => {
  const classes = useStyles();
  const settingDollar = useSelector((state) => state.payment.settingDollar);

  return (
    <>
      <Accordion defaultExpanded={true} className={classes.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className={classes.accordionHeader}
        >
          <Icon className={classes.iconPrice}>monetization_on</Icon>
          <Typography className={classes.heading}>
            <Translator transRef={'translation.Total'} />
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          {props.courses &&
            props.courses.map((row, i) => (
              <div key={row.atv_id + i} className={classes.detailCourse}>
                <Typography variant="caption">
                  <Translator transRef={`translation.${row.desc_aga}`} />
                </Typography>
                <Grid className={classes.gridValue}>
                  <Typography variant="caption">
                    {formatMoney.toReal(row.valor)}
                    {settingDollar?.filter((sd) => sd.codigo === 683)?.length >
                      0 &&
                      '/$' +
                        formatMoney.toReal(
                          row.valor /
                            settingDollar?.filter((sd) => sd.codigo === 683)[0]
                              .valor_referencia,
                        )}
                  </Typography>
                  <Icon
                    onClick={() => props.handleCloseCourse(row)}
                    className={classes.iconClose}
                  >
                    cancel
                  </Icon>
                </Grid>
              </div>
            ))}
          {!props.hideVoucher && (
            <div className={classes.detailDiscount}>
              <Icon className={classes.iconDiscount} fontSize="large">
                local_activity
              </Icon>
              <TextField
                className={classes.textTicket}
                label="Voucher"
                autoComplete="off"
                variant="outlined"
                name="voucher"
                value={props.valueVoucher}
                onChange={(e) => props.onChangeVoucher(e)}
              />
            </div>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
};
export default CardComponent;
