import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch,
  useParams,
  useLocation,
} from 'react-router-dom';

import CourseViewContainer from './CourseViewContainer';

const CourseContainer = () => {
  return (
      <Switch>
        <Route path={`/course/:centro_custo_id`}>
          <CourseViewContainer />
        </Route>
        <Route path="/course">
          <CourseViewContainer />
        </Route>
      </Switch>
  );
};

export default CourseContainer;
