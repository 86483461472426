import * as actions from './actions';
import produce from 'immer';

const initialState = {
  inPayment: false,
  subscription: [],
  type: '',
  total: 0,
  voucher: {},
  codVoucher: '',
  statusReceipt: null,
  settingDollar: {},
};

export const reducer = produce((state, action) => {
  switch (action.type) {
    case actions.SET_ALL_SUBSCRIPTION:
      state.inPayment = true;
      state.subscription = action.payload.subscription;
      break;
    case actions.SET_SUBSCRIPTION:
      state.inPayment = true;
      state.subscription.push(action.payload.subscription);
      break;
      case actions.SET_SETTING_DOLLAR:
      state.settingDollar = action.payload.valor;
      break;
    case actions.RESET_SUBSCRIPTION:
      state.subscription = state.subscription.filter((r) => {
        if (
          r.inscricao_uuid &&
          r.inscricao_uuid !== action.payload.data.inscricao_uuid
        ) {
          return r;
        }
        if (r.id && r.id !== action.payload.data.id) {
          return r;
        }
      });
      break;
    case actions.CLEAN_SUBSCRIPTION:
      state.subscription = [];
      break;
    case actions.SET_PAYMENT_TYPE:
      state.type = action.payload.paymentType;
      break;
    case actions.SET_TOTAL:
      state.total = action.payload.total;
      break;
    case actions.SET_IN_PAYMENT:
      state.inPayment = false;
      break;
    case actions.SET_VOUCHER:
      state.voucher = action.payload.voucher;
      break;
    case actions.SET_COD_VOUCHER:
      state.codVoucher = action.payload.codVoucher;
      break;
    case actions.SET_STATUSRECEIPT:
      state.statusReceipt = action.payload.data;
      break;
    case actions.RESET_INITIALSTATE:
      state.inPayment = false;
      state.subscription = [];
      state.type = '';
      state.total = 0;
      state.voucher = '';
      state.codVoucher = '';
      state.statusReceipt = null;
      break;
  }
}, initialState);
