import React, { useState, useEffect } from 'react';
import { Config, useFetchApi, Loading, formatDate, Translator } from '@itarget/shared';
import { course, payment, setting as settingRedux } from '../../../redux';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  Typography,
  Container,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import Layout from '../../../components/Layout';
import DiscountViewContainer from '../Course/DiscountViewContainer';
import CardStatusComponent from '../../../components/CardCourseStatus';
import 'react-credit-cards/es/styles-compiled.css';
import useStyles from './style';

const ListingViewContainer = () => {
  const classes = useStyles();
  const routeParams = useParams();
  const dispatch = useDispatch();
  const [checkboxState, setCheckboxState] = useState({});
  const [listCourseSelected, setListCourseSelected] = useState([]);
  const [inscricoes, setInscricoes] = useState([]);
  const [openConfirmUnsubscribe, setOpenConfirmUnsubscribe] = useState(false);
  const [currentRow, setCurrentRow] = useState();
  const [textWelcome, setTextWelcome] = useState('');

  const user = useSelector((state) => state.auth.user);
  const selectedCourse = useSelector((state) => state.course.courseSelected);
  const selectedLang = useSelector((state) => state.language.lang);

  const configIdLanguage = selectedLang === 'es' ? 3 : selectedLang === 'en' ? 2 : 1;

  const {
    data: inscricoesApi,
    isLoading: isLoadingInscricoes,
    // error: erroInscricoes,
    fetchData: fetchInscricoes,
  } = useFetchApi(
    Config.baseURL +
    `api/${selectedLang}/subscription?pessoa_id=${user.data.id}&centro_custo_id=${routeParams.centro_custo_id}`,
  );

  const { data: setting, fetchData: fetchSetting } = useFetchApi(
    Config.baseURL +
      `api/${selectedLang}/setting?centro_custo_id=${routeParams.centro_custo_id}&codigo[]=377&codigo[]=9&codigo[]=13`,
  );

  useEffect(() => {
    if (setting?.data) {
      dispatch(settingRedux.actions.setSettings(setting.data));
    }
    if (setting?.data?.find((r) => r.codigo === 377 && r.idioma_id === configIdLanguage)?.valor_referencia) {
      setTextWelcome(
        setting?.data?.find((r) => r.codigo === 377 && r.idioma_id === configIdLanguage)?.valor_referencia,
      );
    }
  }, [setting, selectedLang]);

  useEffect(() => {
    if (inscricoesApi?.data?.length > 0) {
      setInscricoes(inscricoesApi.data);
    }
  }, [inscricoesApi]);

  useEffect(() => {
    fetchInscricoes();
    fetchSetting();
  }, []);

  useEffect(() => {
    fetchInscricoes();
  }, [selectedLang])

  useEffect(() => {
    setCheckboxState(
      selectedCourse.map((r) => {
        return r.conta_receber_id;
      }),
    );
    if (listCourseSelected > selectedCourse) {
      setListCourseSelected(selectedCourse);
    }
  }, [selectedCourse]);

  useEffect(() => {
    dispatch(course.actions.setSelectCourse(listCourseSelected));
  }, [listCourseSelected]);

  const handleChangeCheckbox = (row, event) => {
    if (event.target.checked) {
      if (!row.desc_aga) {
        row.desc_aga = row.descricao;
      }

      if (row.cr_valor) {
        Object.defineProperty(row, 'valor', {
          value: row.cr_valor,
        });
      }
      dispatch(payment.actions.setSubscription(row));
      setListCourseSelected((listCourseSelected) => [
        ...listCourseSelected,
        row,
      ]);
    } else {
      setListCourseSelected(listCourseSelected.filter((res) => res !== row));
      dispatch(payment.actions.resetSubscription(row));
    }
  };

  const handleClickOpen = () => {
    setOpenConfirmUnsubscribe(true);
  };

  const handleClose = () => {
    setOpenConfirmUnsubscribe(false);
  };

  const openModal = (row) => {
    handleClickOpen();
    setCurrentRow(row);
  };

  const onClickUnsubscribe = () => {
    dispatch({
      type: 'FETCH_STARTED',
      meta: {
        actions: payment.actions.SUBSCRIPTION,
        body: JSON.stringify({
          pessoa_id: user.data && user.data.id,
          id: currentRow.inscricao_uuid,
          status_inscricao: 3,
        }),
        url:
          Config.baseURL +
          `api/${selectedLang}/subscription/${currentRow.inscricao_uuid}`,
        method: 'PUT',
      },
    });
    setInscricoes(
      inscricoes.filter(
        (res) => res.inscricao_uuid !== currentRow.inscricao_uuid,
      ),
    );
    handleClose();
  };

  const reprintBillet = async (row) => {
    var my_form = document.createElement('FORM');
    my_form.name = 'myForm';
    my_form.method = 'POST';
    my_form.action = 'https://shopline.itau.com.br/shopline/shopline.aspx';
    my_form.target = '_blank';

    var my_tb = document.createElement('INPUT');
    my_tb.type = 'HIDDEN';
    my_tb.name = 'dc';
    my_tb.value = row.hash_transacao;
    my_form.appendChild(my_tb);

    document.body.appendChild(my_form);
    my_form.submit();
  };

  return (
    <Layout
      content={
        <Container className={classes.container} maxWidth="xl">
          <Dialog
            open={openConfirmUnsubscribe}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              <Translator transRef={'translation.Deseja cancelar a sua inscrição?'} />
            </DialogTitle>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                <Translator transRef={'translation.Voltar'} />
              </Button>
              <Button onClick={onClickUnsubscribe} color="primary" autoFocus>
                <Translator transRef={'translation.Cancelar inscrição'} />
              </Button>
            </DialogActions>
          </Dialog>
          <DiscountViewContainer />
          <Grid container className={classes.grid}>
            <Grid item xs={12}>
              <Typography className={classes.textHeader} gutterBottom>
                <Translator transRef={`translation.${textWelcome}`} />
              </Typography>
            </Grid>
            <Grid container spacing={3}>
              {isLoadingInscricoes ? (
                <Loading text="Carregando..." />
              ) : inscricoes && inscricoes.length > 0 ? (
                inscricoes
                  .slice()
                  .sort((a, b) =>
                    a.data_vencimento > b.data_vencimento ? 1 : -1,
                  )
                  .map((row, i) => (
                    <Grid item xs={12} sm={12} md={12} lg={12} key={i}>
                      <FormControlLabel
                        control={
                          <CardStatusComponent
                            row={row}
                            reprintBillet={() => reprintBillet(row)}
                            onClickCancel={() => openModal(row)}
                            nameCourse={row.descricao}
                            nameTypeActivity={row.tipo_atividade_descricao}
                            vencimento={formatDate.ptBr(row.data_vencimento)}
                            price={row.cr_valor}
                            status={
                              row.status_conta_receber_descricao
                                ? row.status_conta_receber_descricao
                                : row.status_inscricao_descricao
                            }
                            status_code={row.status_conta_receber}
                            recibo={
                              row['link_recibo'] &&
                              row['link_recibo'].replace('cbr', 'newcbr')
                            }
                            handleChangeCheckbox={(ev) =>
                              handleChangeCheckbox(row, ev)
                            }
                            checkboxState={checkboxState}
                            name={row.conta_receber_id}
                          />
                        }
                      />
                    </Grid>
                  ))
              ) : (
                <Grid item xs={12}>
                  <Typography className={classes.textHeader} gutterBottom>
                    <Translator transRef={'translation.Nenhuma inscrição encontrada'} />
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Container>
      }
    />
  );
};

export default ListingViewContainer;
