import MODULE_NAME from './constants';
import { utils } from '../fetch';

export const SUBSCRIPTION = utils.buildActions(MODULE_NAME, 'SUBSCRIPTION');

export const VOUCHER = utils.buildActions(MODULE_NAME, 'VOUCHER');

export const SET_SUBSCRIPTION = `${MODULE_NAME}/SET_SUBSCRIPTION`;
export const SET_ALL_SUBSCRIPTION = `${MODULE_NAME}/SET_ALL_SUBSCRIPTION`;

export const RESET_SUBSCRIPTION = `${MODULE_NAME}/RESET_SUBSCRIPTION`;

export const SET_PAYMENT_TYPE = `${MODULE_NAME}/SET_PAYMENT_TYPE`;

export const SET_TOTAL = `${MODULE_NAME}/SET_TOTAL`;

export const SET_IN_PAYMENT = `${MODULE_NAME}/SET_IN_PAYMENT`;

export const SET_VOUCHER = `${MODULE_NAME}/SET_VOUCHER`;
export const SET_COD_VOUCHER = `${MODULE_NAME}/SET_COD_VOUCHER`;

export const RESET_INITIALSTATE = `${MODULE_NAME}/RESET_INITIALSTATE`;

export const CLEAN_SUBSCRIPTION = `${MODULE_NAME}/CLEAN_SUBSCRIPTION`;

export const SET_STATUSRECEIPT = `${MODULE_NAME}/SET_STATUSRECEIPT`;

export const SET_SETTING_DOLLAR = `${MODULE_NAME}/SET_SETTING_DOLLAR`;

export const setSettingDollar = (data) => ({
  type: SET_SETTING_DOLLAR,
  payload: { valor: data },
});

export const setSubscription = (data) => ({
  type: SET_SUBSCRIPTION,
  payload: { subscription: data, id: data.id },
});

export const setAllSubscription = (data) => ({
  type: SET_ALL_SUBSCRIPTION,
  payload: { subscription: data },
});

export const setPaymentType = (paymentType) => ({
  type: SET_PAYMENT_TYPE,
  payload: { paymentType },
});

export const setTotal = (total) => ({
  type: SET_TOTAL,
  payload: { total },
});

export const setInpayment = () => ({
  type: SET_IN_PAYMENT,
});

export const setVoucher = (voucher) => ({
  type: SET_VOUCHER,
  payload: { voucher },
});
export const setCodVoucher = (codVoucher) => ({
  type: SET_COD_VOUCHER,
  payload: { codVoucher },
});

export const cleanSubscription = () => ({
  type: CLEAN_SUBSCRIPTION,
});
export const resetInitialstate = () => ({
  type: RESET_INITIALSTATE,
});

export const resetSubscription = (data) => ({
  type: RESET_SUBSCRIPTION,
  payload: { data },
});

export const setStatusReceipt = (data) => ({
  type: SET_STATUSRECEIPT,
  payload: { data },
});
